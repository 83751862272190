import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initialReferencesApi } from '../action/initialReferencesApi';

// Async thunk for saving initial references
export const saveInitialReferences = createAsyncThunk(
    'initialReferences/save',
    async (data, thunkAPI) => {
      try {
        const response = await thunkAPI.dispatch(
          initialReferencesApi.endpoints.createInitialReference.initiate(data)
        );
  
        return response;
      } catch (error) {
        console.error('API Call Failed:', error);
        return thunkAPI.rejectWithValue(error.message || 'Failed to save data');
      }
    }
  );
  
const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

// Slice definition
const initialReferencesSlice = createSlice({
  name: 'initialReferences',
  initialState,
  reducers: {
    // Define any additional synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveInitialReferences.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveInitialReferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(saveInitialReferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to save initial references';
      });
  },
});

export default initialReferencesSlice.reducer;
