import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCanAddRow } from "../../store/reducer/searchSlice";
import { setSelectedRefData } from "../../store/reducer/searchSlice";
import PropTypes from "prop-types";
import "./index.scss";

const Table = ({
  headers,
  formattedData,
  renderAsHtml,
  handleUserRowData,
  handleUpdateRowData,
}) => {
  const [data, setData] = useState(formattedData);
  const canEdit = useSelector((state) => state.search.canEdit);
  const canAddRow = useSelector((state) => state.search.canAddRow);
  const selectedRefData = useSelector((state) => state.search.selectedRefData);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const setCursorPosition = (rowIndex, header) => {
    if (canEdit === true) {
      var element = document.getElementById(
        `editable_span_${rowIndex}_${header}`
      );
      if (element.childNodes.length === 0) {
        const newInput = document.createElement("input");
        newInput.className = "editable-input";
        newInput.textContent = "";
        element.appendChild(newInput);
        element.focus();
      }
    }
  };
  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);
  const onSelectAll = (selectAll) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row) => ({
            ...row,
            Selected: selectAll,
          }))
        : selectedRefData.map((row) => ({
            ...row,
            Selected: selectAll,
          }));
    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, Selected: !row.Selected } : row
          );
    const allSelected = updatedData.every((row) => row.Selected === true);
    if (allSelected === true) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setData(updatedData);
    dispatch(setSelectedRefData(updatedData));
  };

  const handleCellChange = (rowIndex, header, event) => {
    const newValue = event.target.textContent;
    const updatedData =
      selectedRefData.length === 0
        ? data.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          )
        : selectedRefData.map((row, index) =>
            index === rowIndex ? { ...row, [header]: newValue } : row
          );
    setData(updatedData);
    handleUpdateRowData(updatedData);
    if (selectedRefData.length !== 0) {
      dispatch(setSelectedRefData(updatedData));
    }
    return;
  };
  const addBlankRow = () => {
    const newRow = headers.reduce(
      (acc, header) => {
        acc[header] = "";
        return acc;
      },
      { Selected: false }
    );
    setData((prevData) => [...prevData, newRow]);
    handleUserRowData((prevData) => [...prevData, newRow]);
    if (selectedRefData.length !== 0) {
      newRow["Sr.No"] = `${selectedRefData.length + 1}`;
      dispatch(setSelectedRefData([...selectedRefData, newRow]));
    }
    dispatch(setCanAddRow(""));
    return;
  };
  useEffect(() => {
    if (canAddRow === "add-new-ref") {
      addBlankRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddRow]);

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedRefData.length === 0 &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    id={`editable_span_${rowIndex}_${header}`}
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    contentEditable={canEdit === true ? "true" : "false"}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
          {selectedRefData.length !== 0 &&
            selectedRefData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <input
                    type="checkbox"
                    checked={row.Selected}
                    onChange={() => handleRowSelect(rowIndex)}
                  />
                </td>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                    contentEditable={canEdit === true ? "true" : "false"}
                    onFocus={() => setCursorPosition(rowIndex, header)}
                    suppressContentEditableWarning={true}
                    onBlur={(event) => {
                      if (canEdit) handleCellChange(rowIndex, header, event);
                    }}
                  >
                    {!renderAsHtml || header !== "Link" ? row[header] : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
