import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSearchParameters,
  setAddRefData,
} from "../../store/reducer/searchSlice";
import "./search.scss";
import SearchBg from "../../assets/images/search-page-bg.png";
import Input from "../../components/common/input";
import Dropdown from "../../components/common/dropdown";
import Button from "../../components/common/button";
import UploadSection from "../../components/search/upload-section";

const Search = () => {
  const [selectedOption, setSelectedOption] = useState("publication");
  const [selectedFamOption, setSelectedFamOption] = useState("publication");
  const [selectedFamOptionValid, setSelectedFamOptionValid] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [sanitizedValue, setSanitizedValue] = useState("");
  const [isOptionValid, setIsOptionValid] = useState(true);
  const [isInputValid, setIsInputValid] = useState(true);
  const [isDockInputValid, setDockInputValid] = useState(true);
  const [isAddFamValid, setAddFamValid] = useState(true);
  const [isFamValid, setFamValid] = useState(true);
  const [isFileNoValid, setFileNoValid] = useState(true);
  const [uploadFiles, setUploadFiles] = useState(0);
  const [uploadfamilyMember, setFamilyMember] = useState("");
  const [uploadAddFamMember, setAddFamMember] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const docketNumber = useSelector((state) => state.search.docketNumber);
  const [dockNo, setDockNo] = useState(docketNumber === "" ? "" : docketNumber);
  const number = useSelector((state) => state.search.number);
  const type = useSelector((state) => state.search.type);
  const radioOption = useSelector((state) => state.search.radioOption);
  const [selectedRadio, setSelectedRadio] = useState(
    radioOption === "" ? "" : radioOption
  );
  const [buttonText, setButtonText] = useState(
    "Upload Initial IDS (Upload CSV or XML File)"
  );
  const handleUploadFamilyMember = (data) => {
    setFamilyMember(data);
  };
  const handleDockNo = (data) => {
    setDockNo(data);
  };
  const handleAddFamilyMember = (data) => {
    setAddFamMember(data);
  };
  const handleUploadFileNo = (data) => {
    setUploadFiles(data);
  };
  const handleUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const handleFamSelectedOption = (data) => {
    setSelectedFamOption(data);
  };
  const handleFamValid = (data) => {
    setFamValid(data);
  };
  const handleAddFamValid = (data) => {
    setAddFamValid(data);
  };
  const handleDocketValid = (data) => {
    setDockInputValid(data);
  };
  const handleFileValid = (data) => {
    setFileNoValid(data);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setIsOptionValid(true);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const sanitizeApplicationNumber = (value) => {
      let sanitizedValue = value.replace(/[\/,]/g, "");

      sanitizedValue = sanitizedValue.replace(/[^\dA-Z]/g, "");

      const match = sanitizedValue.match(/^([A-Z]{2})(\d{4})(.*)$/);
      if (match) {
        const countryCode = match[1];
        const year = match[2];
        let rest = match[3];

        rest = rest.replace(/^0+/, "");

        sanitizedValue = `${countryCode}${year}${rest}`;
      }

      if (sanitizedValue.length < 11) {
        sanitizedValue = sanitizedValue.padStart(12, "0");
      } else if (sanitizedValue.length > 11) {
        sanitizedValue = sanitizedValue.slice(0, 12);
      }

      return sanitizedValue;
    };

    const sanitized = sanitizeApplicationNumber(value);
    setSanitizedValue(sanitized);

    const isValidFormat =
      /^[0-9]{11}$/.test(sanitized) ||
      /^[A-Z]{2}[0-9]{5,11}$/.test(sanitized) ||
      /^[A-Z]{2}[0-9]{7,10}[A-Z][0-9]?$/.test(sanitized);
    setIsInputValid(isValidFormat);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const isValidOption = selectedOption && (inputValue || number !== "");
    const isValidInput =
      (inputValue.length >= 7 && isInputValid) ||
      (number.length >= 7 && number !== "");
    if (uploadAddFamMember !== "") {
      const addRefArray = uploadAddFamMember.replace(/\s+/g, "").split(",");
      const isAddRefValid = addRefArray.map((member) => {
        return (
          /^[0-9]{7,}$/.test(member) ||
          /^[A-Z]{2}[0-9]{5,}[A-Z0-9]*$/.test(member)
        );
      });
      const countTrue = isAddRefValid.filter((value) => value === true).length;
      if (countTrue === addRefArray.length) {
        dispatch(setAddRefData(addRefArray));
      }
    }
    const finalValue =
      uploadfamilyMember !== ""
        ? uploadfamilyMember
        : number !== ""
        ? number
        : sanitizedValue;
    const isFamValidOption =
      uploadfamilyMember.length >= 7 || number.length >= 7;

    if (
      selectedRadio === "option1" &&
      dockNo === "" &&
      isFamValidOption === true
    ) {
      setDockInputValid(false);
      return;
    }
    if (
      uploadfamilyMember !== "" &&
      uploadfamilyMember.length >= 7 &&
      dockNo !== ""
    ) {
      const isValidFormat =
        /^[0-9]{7,}$/.test(uploadfamilyMember) ||
        /^[A-Z]{2}[0-9]{5,}$/.test(uploadfamilyMember);
      if (isValidFormat) {
        dispatch(
          setSearchParameters({
            type: selectedOption,
            number: finalValue,
            radioOption: selectedRadio,
            filesUploaded: uploadedFiles,
            additionalRefInput: uploadAddFamMember,
            correspondingFamInput: uploadfamilyMember,
          })
        );
        navigate("/ids", {
          state: {
            type: selectedOption,
            number: finalValue,
            radioOption: selectedRadio,
            filesUploaded: uploadedFiles,
            additionalRefInput: uploadAddFamMember,
            correspondingFamInput: uploadfamilyMember,
          },
        });
        return;
      }
    }
    if (
      selectedRadio === "option1" &&
      dockNo === "" &&
      uploadfamilyMember === "" &&
      uploadedFiles.length !== 0
    ) {
      setDockInputValid(false);
      return;
    }
    if (
      selectedRadio === "option1" &&
      dockNo !== "" &&
      uploadfamilyMember === "" &&
      uploadedFiles.length !== 0
    ) {
      dispatch(
        setSearchParameters({
          type: selectedOption,
          number: finalValue,
          radioOption: selectedRadio,
          filesUploaded: uploadedFiles,
          additionalRefInput: uploadAddFamMember,
          correspondingFamInput: uploadfamilyMember,
        })
      );
      navigate("/ids", {
        state: {
          type: selectedOption,
          number: finalValue,
          radioOption: selectedRadio,
          filesUploaded: uploadedFiles,
          additionalRefInput: uploadAddFamMember,
          correspondingFamInput: uploadfamilyMember,
        },
      });
      return;
    }
    if (
      selectedRadio === "option1" &&
      dockNo === "" &&
      isFamValidOption === false &&
      uploadfamilyMember === "" &&
      uploadAddFamMember === "" &&
      uploadFiles.length === 0
    ) {
      setDockInputValid(false);
      setFamValid(false);
      setAddFamValid(false);
      setFileNoValid(false);
      return;
    }
    if (selectedRadio === "option1" && isFamValidOption === false) {
      setFamValid(false);
      return;
    }
    if (isValidOption && isValidInput) {
      dispatch(
        setSearchParameters({
          type: selectedOption,
          number: finalValue,
          radioOption: selectedRadio,
          filesUploaded: uploadedFiles,
        })
      );
      navigate("/ids", {
        state: {
          type: selectedOption,
          number: finalValue,
          radioOption: selectedRadio,
          filesUploaded: uploadedFiles,
        },
      });
    } else {
      if (!selectedOption) setIsOptionValid(false);
      if (!finalValue || !isValidInput) setIsInputValid(false);
    }
  };

  const dropdownOptions = [
    { value: "application", label: "Application #", disabled: false },
    { value: "publication", label: "Publication #", disabled: false },
    { value: "patent", label: "Patent #", disabled: false },
    { value: "pct", label: "PCT #", disabled: true },
  ];
  return (
    <div className="start-container container-fluid">
      <div
        className={`hero-column ${
          selectedRadio === "option1" ? "hero-prior" : ""
        }`}
      >
        <div className="hero">
          <div className="start-para">
            <div className="heading">
              IDS Let’s <span className="get-started">Get Started</span>
            </div>
            <div className="hero-para">
            By submitting an IDS, you help the patent examiner access all pertinent information, 
            which can facilitate the examination process and strengthen your patent application.
            </div>
          </div>
          <form
            className="start-button-section"
            noValidate
            onSubmit={handleSearch}
          >
            <UploadSection
              selectedRadio={selectedRadio}
              setSelectedRadio={setSelectedRadio}
              buttonText={buttonText}
              setButtonText={setButtonText}
              handleUploadFamilyMember={handleUploadFamilyMember}
              handleAddFamilyMember={handleAddFamilyMember}
              handleDockNo={handleDockNo}
              handleUploadFileNo={handleUploadFileNo}
              handleUploadedFiles={handleUploadedFiles}
              isDockInputValid={isDockInputValid}
              isFamValid={isFamValid}
              isAddFamValid={isAddFamValid}
              isFileNoValid={isFileNoValid}
              handleFamSelectedOption={handleFamSelectedOption}
              selectedFamOptionValid={selectedFamOptionValid}
              handleFamValid={handleFamValid}
              handleAddFamValid={handleAddFamValid}
              handleDocketValid={handleDocketValid}
              handleFileValid={handleFileValid}
            />
            {(selectedRadio === "option2" || selectedRadio !== "option1") && (
              <div className="dropdown-input-container mb-3">
                <Dropdown
                  options={dropdownOptions}
                  className={`application-no dropdown-select form-control ${
                    !isOptionValid ? "is-invalid" : ""
                  }`}
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  required
                />
                <Input
                  className={`application-no form-control ${
                    !isInputValid ? "is-invalid" : ""
                  }`}
                  type="text"
                  placeholder="Enter Number"
                  value={number === "" ? inputValue : number}
                  onChange={handleInputChange}
                />
                {!isInputValid && (
                  <span
                    className="info-icon"
                    data-tooltip="The patent number should be in the following formats: XXXXXXX or REXXXXX (e.g US 7,123,456 would be entered as 7123456; US RE41234 would be entered as RE41234)"
                  >
                    i
                  </span>
                )}
              </div>
            )}
            <div className="button-container">
              <Button className="searchnow-btn" type="submit">
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="button-column">
        <div className="image-section">
          <img src={SearchBg} width="672px" height="309px" alt="search-image" />
        </div>
      </div>
    </div>
  );
};

export default Search;
