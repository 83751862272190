import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi } from "../action/adminApi";

export const saveAdmin = createAsyncThunk(
  "admin/save",
  async (data, thunkAPI) => {
    try {
      const response = await thunkAPI.dispatch(
        adminApi.endpoints.createAdmin.initiate(data)
      );

      return response;
    } catch (error) {
      console.error("API Call Failed:", error);
      return thunkAPI.rejectWithValue(
        error.message || "Failed to save admin data"
      );
    }
  }
);

const initialState = {
  data: [],
  status: "idle",
  error: null,
  sharePopupToggle: false,
  selectIdsToggle: false,
  idsGenerateForm: false,
  editDashboardTable: "",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setSharePopupToggle: (state, action) => {
      state.sharePopupToggle = action.payload;
    },
    setSelectIdsToggle: (state, action) => {
      state.selectIdsToggle = action.payload;
    },
    setIdsGenerateForm: (state, action) => {
      state.idsGenerateForm = action.payload;
    },
    setEditDashboardTable: (state, action) => {
      state.editDashboardTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(saveAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to save admin data";
      });
  },
});
export const {
  setAdminData,
  setSharePopupToggle,
  setSelectIdsToggle,
  setIdsGenerateForm,
  setEditDashboardTable,
} = adminSlice.actions;
export default adminSlice.reducer;
