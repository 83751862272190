import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  number: '',
  extractedData: [],
  formattedInitialRefData: [],
  extractedExcelData: [],
  docketNumber: '',
  addRefData: []
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchParameters: (state, action) => {
      state.type = action.payload.type;
      state.number = action.payload.number;
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload;
    },
    setFormattedInitialRefData: (state, action) => {
      state.formattedInitialRefData = action.payload;
    },

    setExtractedExcelData: (state, action) => {
      state.extractedExcelData = action.payload;
    },
    setDocketNumber: (state, action) => {
      state.docketNumber = action.payload
    },
    setAddRefData: (state, action) => {
      state.addRefData = action.payload
    }
  },
});

export const { setSearchParameters, setExtractedData, setFormattedInitialRefData, setExtractedExcelData, setDocketNumber, setAddRefData } = searchSlice.actions;
export default searchSlice.reducer;
