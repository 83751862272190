import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Table = ({ headers, formattedData, renderAsHtml }) => {
  const [data, setData] = useState(formattedData);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    setData(formattedData);
  }, [formattedData]);

  const onSelectAll = (selectAll) => {
    const updatedData = data.map((row) => ({
      ...row,
      Selected: selectAll,
    }));
    setData(updatedData);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    onSelectAll(!selectAll);
  };

  const handleRowSelect = (rowIndex) => {
    const updatedData = data.map((row, index) =>
      index === rowIndex ? { ...row, Selected: !row.Selected } : row
    );
    setData(updatedData);
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="checkbox"
                  checked={row.Selected}
                  onChange={() => handleRowSelect(rowIndex)}
                />
              </td>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  dangerouslySetInnerHTML={
                    renderAsHtml && header === "Link"
                      ? { __html: row[header] }
                      : undefined
                  }
                >
                  {!renderAsHtml || header !== "Link" ? row[header] : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
