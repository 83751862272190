import React from "react";

const Dropdown = ({ options, className, value, onChange }) => {
  return (
    <select
      className={className}
      value={value}
      onChange={onChange}
    >
      {options.map((option, index) => (
        <option
          key={index}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
