import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const epoBiblioApi = createApi({
  reducerPath: "epoBiblioApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getEpoBiblio: builder.query({
      query: ({ type, format, number }) =>
        `epo/biblio?type=${type}&format=${format}&number=${number}`,
    }),
  }),
});

export const { useGetEpoBiblioQuery } = epoBiblioApi;
export default epoBiblioApi;
