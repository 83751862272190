import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../common/family-table';
import { formatDate, PRIORITY_ORDER } from '../../../util/utils';
import { useGetPatentBiblioMutation } from '../../../store/action/patentBiblioApi';
import { setFormattedFamilyData } from '../../../store/reducer/familySlice';
import './family-information.scss';

const FamilyInformation = () => {
  const { familyBiblio } = useSelector((state) => state.family);
  const dispatch = useDispatch();
  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];
  const familyMembersArray = useMemo(
    () => (Array.isArray(familyMembers) ? familyMembers : familyMembers ? [familyMembers] : []),
    [familyMembers]
  );

  const [getPatentBiblio] = useGetPatentBiblioMutation();
  const [patentData, setPatentData] = useState({});

  useEffect(() => {
    let isMounted = true;

    const fetchPatentData = async () => {
      const serialNumbers = familyMembersArray.map(member => {
        const applicationNumber = member['application-reference']?.['document-id']?.['doc-number']?.['$'];
        if (applicationNumber) {
          const cleanedSerialNumber = applicationNumber.replace(/^\d{4}/, '').slice(-8);
          return cleanedSerialNumber.length === 8 ? cleanedSerialNumber : applicationNumber;
        }
        return null;
      }).filter(Boolean);

      if (serialNumbers.length === 0) {
        setPatentData({});
        return;
      }

      const requests = serialNumbers.map(serialNumber =>
        getPatentBiblio({ criteria: `patentApplicationNumber:${serialNumber}`, start: 0, rows: 1 }).unwrap()
      );

      try {
        const results = await Promise.all(requests);

        if (!isMounted) return;

        const dataMap = results.reduce((acc, result) => {
          result.response.docs.forEach(doc => {
            const serialNumber = doc.patentApplicationNumber?.[0] || '';
            acc[serialNumber] = {
              status: doc.accessLevelCategory?.[0] || '',
              docketNo: doc.applicantFileReference?.[0] || '',
            };
          });
          return acc;
        }, {});

        setPatentData(dataMap);
      } catch (err) {
        console.error('Error fetching patent data:', err);
      }
    };

    fetchPatentData();

    return () => {
      isMounted = false;
    };
  }, [familyMembersArray, getPatentBiblio]);

  const getPriorityDate = (priorityClaims) => {
    if (!priorityClaims) return '';
    if (Array.isArray(priorityClaims)) {
      return priorityClaims[0]?.['document-id']?.['date']?.['$'] || '';
    } else {
      return priorityClaims['document-id']?.['date']?.['$'] || '';
    }
  };

  const getHighestPriorityApplicant = (applicantsArray) => {
    if (!applicantsArray.length) return '';
    const applicant = applicantsArray.find(app => app['@data-format']) || {};
    return applicant['applicant-name']?.['name']?.['$'] || '';
  };

  const formattedData = useMemo(() => {
    return familyMembersArray.map((member, index) => {
      const publicationReference = member['publication-reference']?.['document-id']?.[0] || {};
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const parties = member['exchange-document']?.['bibliographic-data']?.['parties'] || {};
      const applicantsArray = parties['applicants']?.['applicant'] || [];
      const priorityClaims = member?.['priority-claim'];

      const pubCountry = publicationReference['country']?.['$'] || "";
      const pubDocNumber = publicationReference['doc-number']?.['$'] || "";
      const pubKind = publicationReference['kind']?.['$'] || "";
      const publicationNumber = `${pubCountry}${pubDocNumber}${pubKind}`;
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";
      const filingDate = formatDate(applicationReference['date']?.['$'] || "N.A#");
      const pubDate = formatDate(publicationReference['date']?.['$'] || "N.A#");

      const formattedApplicationNumber = applicationNumber.replace(/^\d{4}/, '').slice(-8);
      const status = patentData[formattedApplicationNumber]?.status || "";
      const docketNo = patentData[formattedApplicationNumber]?.docketNo || "";

      const patentNo = pubDocNumber;
      const date = pubDate;

      const highestPriorityApplicant = getHighestPriorityApplicant(applicantsArray);
      const priorityDate = getPriorityDate(priorityClaims);
      const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited']?.['citation'];
      const refCounts = referencesCited?.length
      const availability = priorityClaims && referencesCited && refCounts ? "Ref# Available" : "Ref# Not Available";
      return {
        "Sr.No": index + 1,
        "Country Code": pubCountry,
        "Serial #": applicationNumber,
        "Filing Date": filingDate,
        "Status": status,
        "Pub. Number": publicationNumber,
        "Pub. Date": pubDate,
        "Patent #": patentNo,
        "Patent Date": date,
        "Attorney Docket #": docketNo,
        "Applicants": highestPriorityApplicant,
        "Priority Date": formatDate(priorityDate),
        "Last IDS Filed Date": "",
        "Availability": availability,
        "citedCounts": refCounts
      };
    });
  }, [familyMembersArray, patentData]);

  const getHighestPriorityDocId = (docIds) => {
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };
  const availableReferenceData = familyMembersArray.flatMap(member => {
    const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
    if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
      return [];
    }
    const publicationRef = member['publication-reference']?.['document-id']?.[0] || {};
    const pubDocNumberRef = publicationRef['doc-number']?.['$'] || "";
    const patentRNo = pubDocNumberRef;
    return referencesCited['citation'].map(citation => {
      const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
      const docId = getHighestPriorityDocId(docIds);

      const country = docId?.['country']?.['$'] || "";
      const docNumber = docId?.['doc-number']?.['$'] || "";
      const kind = docId?.['kind']?.['$'] || "";
      const publicationDate = formatDate(docId?.['date']?.['$'] || "");
      const publicationNumber = `${country}${docNumber}${kind}`;
      const applicationReference = member['application-reference']?.['document-id'] || {};
      const applicationNumber = applicationReference['doc-number']?.['$'] || "N.A#";
      return {
        "Publication No.": publicationNumber || "N.A#",
        "Kind Code": kind || "N.A",
        "Publication Date": publicationDate || "N.A",
        "Serial #": applicationNumber || "N.A#",
        "Patentee Name": docId?.['name']?.['$'] || "N.A",
        "Patent #": patentRNo
      };
    }).filter(row => row !== null);
  });

  useEffect(() => {
    if (formattedData.length > 0) {
      dispatch(setFormattedFamilyData(formattedData));
    }
  }, [formattedData, familyBiblio, dispatch]);

  const headers = [
    "Sr.No",
    "Country Code",
    "Serial #",
    "Filing Date",
    "Status",
    "Pub. Number",
    "Pub. Date",
    "Patent #",
    "Patent Date",
    "Attorney Docket #",
    "Applicants",
    "Priority Date",
    "Last IDS Filed Date",
    "Availability",
  ];

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const defaultData = Array.from({ length: 10 }, () => defaultRow);

  return (
    <div className="family-information">
      <Table headers={headers} availableReferenceData={availableReferenceData} data={formattedData.length > 0 ? formattedData : defaultData} />
    </div>
  );
};

export default FamilyInformation;
