import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetPatentBiblioMutation } from "../../store/action/patentBiblioApi";
import { useGetEpoBiblioQuery } from "../../store/action/epoBiblioApi";
import {
  setPatentBiblioData,
  setLoading,
  setError,
} from "../../store/reducer/patentBiblioSlice";
import { useLocation } from "react-router-dom";
import "./index.scss";
import { saveAdmin, setAdminData } from "../../store/reducer/adminSlice";

const TopContent = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { type, number } = state || {};

  const adminDataList = useSelector((state) => state.admin.data);
  const { patentBiblioData, loading, error } = useSelector(
    (state) => state.patentBiblio
  );
  const { docketNumber } = useSelector((state) => state.search);
  const radioOption = useSelector((state) => state.search.radioOption);
  const { data: epoBiblioData, error: epoError } = useGetEpoBiblioQuery(
    {
      type: type,
      format: "docdb",
      number: number,
    },
    { skip: !number }
  );

  const familyBiblio = useSelector((state) => state.family.familyBiblio);
  const familyMembers =
    familyBiblio?.["ops:world-patent-data"]?.["ops:patent-family"]?.[
    "ops:family-member"
    ] || [];
  const member = familyMembers[0] || {};

  const [getPatentBiblio] = useGetPatentBiblioMutation();

  useEffect(() => {
    const fetchPatentBiblio = async (docNumber) => {
      dispatch(setLoading());
      try {
        const result = await getPatentBiblio({
          criteria: `patentApplicationNumber:${docNumber}`,
          start: 0,
          rows: 100,
        }).unwrap();
        dispatch(setPatentBiblioData(result));
      } catch (err) {
        dispatch(setError(err.message || "Error fetching patent biblio data"));
        console.error("Error fetching patent biblio data:", err);
      }
    };

    if (type === "publication" || type === "application" || type === "patent") {
      if (epoBiblioData) {
        const documentIds =
          epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
          "exchange-document"
          ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"];
        const originalDocNumber = documentIds?.find(
          (doc) => doc["@document-id-type"] === "original"
        )?.["doc-number"]?.["$"];
        if (originalDocNumber) {
          fetchPatentBiblio(originalDocNumber);
        }
      } else if (type === "application" && number) {
        // Handle the case where epoBiblioData is not required for "application" type
        fetchPatentBiblio(number.replace(/^\D+/g, ""));
      }
    } else if (number) {
      fetchPatentBiblio(number.replace(/^\D+/g, ""));
    }
  }, [dispatch, getPatentBiblio, number, epoBiblioData, type]);

  const getValue = (value) =>
    typeof value === "object" && value !== null
      ? value["$"] || "N/A"
      : value || "N/A";

  const responseDoc = patentBiblioData?.response?.docs?.[0] || {};
  const applicantData =
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["parties"]?.["applicants"]?.["applicant"] || [];
  const epoSerialNoObject =
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"][0] ||
    {};
  const epoDocumentArray =
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"] || [];
  const epoSerialNoNumber = epoSerialNoObject["doc-number"]?.["$"] || "";
  const epoFilingDate =
    epoSerialNoObject["date"]?.["$"] || epoDocumentArray[2]?.["date"]?.["$"]
      ? epoDocumentArray[2]?.["date"]?.["$"]
      : epoDocumentArray[1]?.["date"]?.["$"]
        ? epoDocumentArray[1]?.["date"]?.["$"]
        : "";
  const epoInventionTitle =
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
    "exchange-document"
    ]?.["bibliographic-data"]?.["invention-title"]?.["$"] || "";
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0];
  };
  const formatHyphenDate = (dateStr) => {
    if (!dateStr || dateStr.length !== 8) return "";
    return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
      6,
      8
    )}`;
  };

  const filingDate = loading
    ? ""
    : formatDate(responseDoc["filingDate"]) || "N/A";

  const attorneyDocketNumber =
    getValue(responseDoc?.applicantFileReference?.[0]) !== "N/A"
      ? getValue(responseDoc?.applicantFileReference?.[0])
      : docketNumber || "N/A";
  const data = {
    "Serial #":
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(responseDoc?.patentApplicationNumber?.[0]) !== "N/A"
            ? getValue(responseDoc?.patentApplicationNumber?.[0])
            : epoSerialNoNumber !== ""
              ? epoSerialNoNumber.slice(4)
              : "N/A"
        : "N/A",
    "Filing Date":
      radioOption !== "option1"
        ? filingDate !== "N/A"
          ? filingDate
          : epoFilingDate !== ""
            ? formatHyphenDate(epoFilingDate)
            : "N/A"
        : "N/A",
    "Attorney Docket #":
      radioOption !== "option1"
        ? loading
          ? ""
          : attorneyDocketNumber
        : docketNumber,
    Applicant:
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(applicantData[0]?.["applicant-name"]?.["name"]?.["$"])
        : "N/A",
    "Title of Invention":
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(responseDoc?.inventionTitle?.[0]) !== "N/A"
            ? getValue(responseDoc?.inventionTitle?.[0])
            : epoInventionTitle !== ""
              ? epoInventionTitle
              : "N/A"
        : "N/A",
    Status:
      radioOption !== "option1"
        ? loading
          ? ""
          : getValue(responseDoc?.accessLevelCategory?.[0])
        : "N/A",
  };

  const adminData = {
    Id_num: number,
    family_mem: number,
    serial_num: getValue(responseDoc?.patentApplicationNumber?.[0]),
    docket_num: attorneyDocketNumber,
    applicants: getValue(applicantData[0]?.["applicant-name"]?.["name"]?.["$"]),
    title: getValue(responseDoc?.inventionTitle?.[0]),
    status: "PENDING",
  };

  useEffect(() => {
    const saveData = async () => {
      if (
        typeof adminDataList === "object" &&
        !Array.isArray(adminDataList) &&
        radioOption !== "option1"
      ) {
        const updatedAdminData = {
          ...adminDataList,
          ...adminData,
        };
        const { error, ...filteredAdminData } = updatedAdminData;
        await dispatch(saveAdmin(filteredAdminData));
        dispatch(setAdminData(filteredAdminData));
      } else if (
        typeof adminDataList === "object" &&
        !Array.isArray(adminDataList) &&
        radioOption === "option1"
      ) {
        const updatedAdminData = {
          ...adminDataList,
          ...adminData,
        };
        const { error, ...filteredAdminData } = updatedAdminData;
        await dispatch(saveAdmin(filteredAdminData));
        dispatch(setAdminData(filteredAdminData));
      } else {
        console.error("adminDataList is not an object:", adminDataList);
      }
    };
    saveData();
  }, [patentBiblioData, epoBiblioData]);

  return (
    <div className="card-content">
      {radioOption !== "option1" && loading && <div>Loading...</div>}
      {radioOption !== "option1" && error && <div>Error: {error}</div>}
      {Object.keys(data).map((key, index) => (
        <div className="row" key={index}>
          <div className="key">{key}</div>
          <div className="value">{data[key]}</div>
        </div>
      ))}
    </div>
  );
};

export default TopContent;
