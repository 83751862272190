import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../common/table';
import { setFormattedInitialRefData } from '../../../store/reducer/searchSlice';

function InitialReferences() {
  const dispatch = useDispatch();
  const { extractedData: dataTable } = useSelector((state) => state.search);

  // Filter out rows with 'NON-PATENT LITERATURE DOCUMENTS' type or absent type
  const filteredDataTable = useMemo(() => {
    return (dataTable || []).filter(row => {
      const type = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading'] || '';
      return type !== 'NON-PATENT LITERATURE DOCUMENTS' && type !== '';
    });
  }, [dataTable]);

  const formatCells = (cells, headers) => {
    return headers.reduce((acc, header, index) => {
      const cell = cells[index] || {};
      const text = cell['uscom:TextInput']?.['__text'] || cell['uscom:TextInput'] || '';
      return { ...acc, [header]: text };
    }, {});
  };

  const formattedData = useMemo(() => {
    return filteredDataTable.flatMap((row, index) => {
      const tableHeader = row?.['uscom:TableRow'][0]?.['uscom:TableHeaderCell']?.['uscom:Heading'] || '';
      const tableHeaders = row?.['uscom:TableRow'][1]?.['uscom:TableHeaderCell'] || [];
      const tableDataCells = row?.['uscom:TableRow'].slice(2) || [];

      return tableDataCells.map((dataCell) => {
        const cells = dataCell['uscom:TableDataCell'] || [];
        const data = formatCells(cells, tableHeaders);

        switch (tableHeader) {
          case 'FOREIGN PATENT DOCUMENTS':
            return {
              "Sr.No": index + 1,
              "Publication / Application Number": cells[2]?.['uscom:TextInput'] || '',
              "Kind Code": cells[4]?.['uscom:TextInput'] || '',
              "Issue / Publication Date": cells[5]?.['uscom:TextInput'] || '',
              "Patentee": cells[6]?.['uscom:TextInput'] || '',
              "Type": tableHeader,
            };
          case 'U.S.PATENTS':
            return {
              "Sr.No": index + 1,
              "Publication / Application Number": cells[2] ? `US${cells[2]['uscom:TextInput'] || ''}` : '',
              "Kind Code": cells[3]?.['uscom:TextInput'] || '',
              "Issue / Publication Date": cells[4]?.['uscom:TextInput'] || '',
              "Patentee": cells[5]?.['uscom:TextInput'] || '',
              "Type": tableHeader,
            };
          default:
            return {
              "Sr.No": index + 1,
              "Publication / Application Number": data["Publication Number"] ? `US${data["Publication Number"]}` : '',
              "Kind Code": data["Kind Code"] || '',
              "Issue / Publication Date": data["Publication Date"] || '',
              "Patentee": data["Name of Patentee or Applicant of cited Document"] || '',
              "Type": tableHeader,
            };
        }
      });
    });
  }, [filteredDataTable]);

  useEffect(() => {
    dispatch(setFormattedInitialRefData(formattedData));
  }, [formattedData, dispatch]);

  const headers = [
    "Sr.No",
    "Publication / Application Number",
    "Kind Code",
    "Issue / Publication Date",
    "Patentee",
    "Type",
  ];

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const data = Array.from({ length: 10 }, () => defaultRow);

  return (
    <div className="initial-ref">
      <Table headers={headers} data={formattedData.length > 0 ? formattedData : data} />
    </div>
  );
}

export default InitialReferences;
