import { createSlice, createAction } from "@reduxjs/toolkit";

export const revertAll = createAction("REVERT_ALL");

const initialState = {
  type: "",
  number: "",
  extractedData: [],
  formattedInitialRefData: [],
  extractedExcelData: [],
  docketNumber: "",
  radioOption: "",
  filesUploaded: [],
  additionalRefInput: "",
  correspondingFamInput: "",
  addRefData: [],
  canEdit: false,
  canAddRow: "",
  formattedIdsData: [],
  discardedIdsData: [],
  selectedRefData: [],
  selectedNplData: [],
  activeReduxTab: "",
  discardedNplData: [],
  extractedNplData: [],
  familyNplData: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setSearchParameters: (state, action) => {
      state.type = action.payload.type;
      state.number = action.payload.number;
      state.radioOption = action.payload.radioOption;
      state.filesUploaded = action.payload.filesUploaded;
      state.correspondingFamInput = action.payload.correspondingFamInput;
      state.additionalRefInput = action.payload.additionalRefInput;
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload;
    },
    setFormattedInitialRefData: (state, action) => {
      state.formattedInitialRefData = action.payload;
    },

    setExtractedExcelData: (state, action) => {
      state.extractedExcelData = action.payload;
    },
    setDocketNumber: (state, action) => {
      state.docketNumber = action.payload;
    },
    setAddRefData: (state, action) => {
      state.addRefData = action.payload;
    },
    setCanEdit: (state, action) => {
      state.canEdit = action.payload;
    },
    setCanAddRow: (state, action) => {
      state.canAddRow = action.payload;
    },
    setFormattedIdsData: (state, action) => {
      state.formattedIdsData = action.payload;
    },
    setDiscardedIdsData: (state, action) => {
      state.discardedIdsData = action.payload;
    },
    setSelectedRefData: (state, action) => {
      state.selectedRefData = action.payload;
    },
    setSelectedNplData: (state, action) => {
      state.selectedNplData = action.payload;
    },
    setActiveReduxTab: (state, action) => {
      state.activeReduxTab = action.payload;
    },
    setDiscardedNplData: (state, action) => {
      state.discardedNplData = action.payload;
    },
    setExtractedNplData: (state, action) => {
      state.extractedNplData = action.payload;
    },
    setFamilyNplData: (state, action) => {
      state.familyNplData = action.payload;
    },
  },
});

export const {
  setSearchParameters,
  setExtractedData,
  setFormattedInitialRefData,
  setExtractedExcelData,
  setDocketNumber,
  setAddRefData,
  setCanEdit,
  setCanAddRow,
  setFormattedIdsData,
  setDiscardedIdsData,
  setSelectedRefData,
  setSelectedNplData,
  setActiveReduxTab,
  setDiscardedNplData,
  setExtractedNplData,
  setFamilyNplData,
} = searchSlice.actions;
export default searchSlice.reducer;
