// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.citation-matrix {
  width: 100%;
}
.citation-matrix .publication-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.citation-matrix .publication-label input {
  position: absolute;
  left: 0;
  padding-left: 20px;
}
.citation-matrix .publication-label .publication-number {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/patent-view/citation-matrix/citation-matrix.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AAAF;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,OAAA;EACA,kBAAA;AAAN;AAGI;EACE,gBAAA;AADN","sourcesContent":["@import \"../../../index.css\";\n\n.citation-matrix {\n  width: 100%;\n\n  .publication-label {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n\n    input {\n      position: absolute;\n      left: 0;\n      padding-left: 20px; \n    }\n\n    .publication-number {\n      margin-left: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
