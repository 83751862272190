import React from "react";
import Idsmodal from "./idsmodal";
import "./idsform.scss";
import IdsFormFooter from "./idsform-footer";

const IdsForm = () => {
  return (
    <div className="idsform-container d-flex justify-content-between">
      <Idsmodal />
      <IdsFormFooter />
    </div>
  );
};

export default IdsForm;
