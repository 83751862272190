import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetPatentBiblioMutation } from "../../store/action/patentBiblioApi";
import { useGetEpoBiblioQuery } from "../../store/action/epoBiblioApi";
import {
  setPatentBiblioData,
  setLoading,
  setError,
} from "../../store/reducer/patentBiblioSlice";
import { useLocation } from "react-router-dom";
import "./index.scss";

const TopContent = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { type, number } = state || {};

  const { patentBiblioData, loading, error } = useSelector(
    (state) => state.patentBiblio
  );
  const { docketNumber } = useSelector((state) => state.search);
  const { data: epoBiblioData, error: epoError } = useGetEpoBiblioQuery(
    {
      type: type,
      format: "docdb",
      number: number,
    },
    { skip: !number }
  );

  const familyBiblio = useSelector((state) => state.family.familyBiblio);
  const familyMembers =
    familyBiblio?.["ops:world-patent-data"]?.["ops:patent-family"]?.[
      "ops:family-member"
    ] || [];
  const member = familyMembers[0] || {};

  const [getPatentBiblio] = useGetPatentBiblioMutation();

  useEffect(() => {
    const fetchPatentBiblio = async (docNumber) => {
      dispatch(setLoading());
      try {
        const result = await getPatentBiblio({
          criteria: `patentApplicationNumber:${docNumber}`,
          start: 0,
          rows: 100,
        }).unwrap();
        dispatch(setPatentBiblioData(result));
      } catch (err) {
        dispatch(setError(err.message || "Error fetching patent biblio data"));
        console.error("Error fetching patent biblio data:", err);
      }
    };
    // Check if the type is one of the required types
    if (type === "publication" || type === "application" || type === "patent") {
      if (epoBiblioData) {
        const documentIds =
          epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
            "exchange-document"
          ]?.["bibliographic-data"]?.["application-reference"]?.["document-id"];
        const originalDocNumber = documentIds?.find(
          (doc) => doc["@document-id-type"] === "original"
        )?.["doc-number"]?.["$"];
        console.log("application no>>>", originalDocNumber);
        if (originalDocNumber) {
          fetchPatentBiblio(originalDocNumber);
        }
      } else if (type === "application" && number) {
        // Handle the case where epoBiblioData is not required for "application" type
        fetchPatentBiblio(number.replace(/^\D+/g, ""));
      }
    } else if (number) {
      fetchPatentBiblio(number.replace(/^\D+/g, ""));
    }
  }, [dispatch, getPatentBiblio, number, epoBiblioData, type]);
  

  const getValue = (value) =>
    typeof value === "object" && value !== null ? value["$"] || "N/A" : value || "N/A";

  const responseDoc = patentBiblioData?.response?.docs?.[0] || {};
  const applicantData =
    epoBiblioData?.["ops:world-patent-data"]?.["exchange-documents"]?.[
      "exchange-document"
    ]?.["bibliographic-data"]?.["parties"]?.["applicants"]?.["applicant"] || [];

    const formatDate = (dateString) => {
      if (!dateString) return "";
      return dateString.split("T")[0];
    };
    
    const filingDate = loading ? "" : formatDate(responseDoc["filingDate"]) || 'N/A';

  const attorneyDocketNumber =
    getValue(responseDoc?.applicantFileReference?.[0]) !== "N/A"
      ? getValue(responseDoc?.applicantFileReference?.[0])
      : docketNumber || "N/A";

  const data = {
    "Serial #": loading ? "" : getValue(responseDoc?.patentApplicationNumber?.[0]),
    "Filing Date": filingDate,
    "Attorney Docket #": loading ? "" : attorneyDocketNumber,
    Applicant: loading ? "" : getValue(applicantData[0]?.["applicant-name"]?.["name"]?.["$"]),
    "Title of Invention": loading ? "" : getValue(responseDoc?.inventionTitle?.[0]),
    Status: loading ? "" : getValue(responseDoc?.accessLevelCategory?.[0]),
  };

  return (
    <div className="card-content">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {Object.keys(data).map((key, index) => (
        <div className="row" key={index}>
          <div className="key">{key}</div>
          <div className="value">{data[key]}</div>
        </div>
      ))}
    </div>
  );
};

export default TopContent;
