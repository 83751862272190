import * as XLSX from 'xlsx';
const ExcelReader = async (file) => {
    try {
        const data = new Uint8Array(await file.arrayBuffer());
        const workbook = XLSX.read(data, { type: 'array' });

        // Assume data is in the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const references = jsonData.filter((row) => row.length > 0).slice(1).map((row) => row[0]);


        return references;
    } catch (error) {
        console.error('Error reading Excel file:', error);
        throw error;
    }
};

export default ExcelReader;