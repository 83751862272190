import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Table = ({ headers, data, renderAsHtml }) => {
  return (
    <div className="table-container dashboard-table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  dangerouslySetInnerHTML={
                    renderAsHtml && header === "Link"
                      ? { __html: row[header] }
                      : undefined
                  }
                >
                  {!renderAsHtml || header !== "Link" ? row[header] : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
