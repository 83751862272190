import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const initialReferencesApi = createApi({
  reducerPath: "initialReferencesApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createInitialReference: builder.mutation({
      query: (data) => ({
        url: 'initial-references',
        method: 'POST',
        body: data,
      }),
    }),
    getSavedInitialRef: builder.query({
      query: () => `initial-references`,
    }),
  }),
});

export const { useCreateInitialReferenceMutation, useGetSavedInitialRefQuery } = initialReferencesApi;
export default initialReferencesApi;

