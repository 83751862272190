import { useDispatch } from 'react-redux';
import { XMLParser } from 'fast-xml-parser';
import { setExtractedData } from '../../store/reducer/searchSlice';
import { useEffect } from 'react';

const XMLDataExtractor = ({ files }) => {
  const dispatch = useDispatch();

  const handleFileRead = (fileContent) => {
    const parser = new XMLParser();
    const jsonObj = parser.parse(fileContent);
    const dataTable = extractDataTable(jsonObj);
    return dataTable;
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = handleFileRead(e.target.result);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  useEffect(() => {
    if (files && files.length > 0) {
      Promise.all(files.map(file => readFile(file)))
        .then(results => {
          const combinedData = results.flat(); // Combine data from all files
          dispatch(setExtractedData(combinedData));
        })
        .catch(error => {
          console.error('Error reading files:', error);
        });
    }
  }, [files, dispatch]);

  return null;
};

const extractDataTable = (jsonData) => {
  try {
    const dataTable = jsonData['uspat:IncomingDocument']?.['uscom:FormBag']?.['uscom:Form']?.['uscom:FormPage']?.['uscom:FormPageBody']?.['uscom:DataTable'];
    return Array.isArray(dataTable) ? dataTable : [];
  } catch (error) {
    console.error('Error extracting data table:', error);
    return [];
  }
};

export default XMLDataExtractor;
