import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Signatureform = (props) => {
  const { field1, field2 } = props;
  return (
    <Form
      className={`form-class signatureform-class d-flex justify-content-between`}
      style={{
        margin: "auto",
        paddingBottom: field1 === "Signature" ? "10px" : "0px",
      }}
    >
      <Row className="row-container">
        <Col className="col3-container">
          <Form.Label>{field1}</Form.Label>
          <Form.Control
            className="signatureinput"
            type="text"
            placeholder={field1}
          />
        </Col>
        <Col className="col3-container">
          <Form.Label>{field2}</Form.Label>
          <Form.Control
            className="signatureinput"
            type="text"
            placeholder={field2}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Signatureform;
