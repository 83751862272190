
export const PRIORITY_ORDER = [undefined, 'original', 'docdb', 'docdba', 'epodoc', 'translated', 'lni', 'lookup'];

/**
 * Formats a date string from yyyymmdd to yyyy-mm-dd.
 * @param {string} dateStr - The date string in yyyymmdd format.
 * @returns {string} - The formatted date string in yyyy-mm-dd format.
 */
export const formatDate = (dateStr) => {
    if (!dateStr || dateStr.length !== 8) return '';
    return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`;
  };
  
/**
 * Searches within a text by filtering it based on the query.
 * @param {string} text - The text to search within.
 * @param {string} query - The search query.
 * @returns {boolean} - Whether the text matches the search query.
 */
export const searchInText = (text, query) => {
    if (!query) return true;
    return text.toLowerCase().includes(query.toLowerCase());
  };