import React from 'react';
import PropTypes from 'prop-types';

const TabButton = ({ isActive, onClick, children }) => {
  return (
    <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
      {children}
    </div>
  );
};

TabButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabButton;
