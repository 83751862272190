import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import TableEdit from "../../assets/images/table-edit-icon.png";
import TableDownload from "../../assets/images/table-download-icon.png";
import DashboardShare from "../../assets/images/dashboard-share-icon.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectIdsToggle,
  setSharePopupToggle,
  setEditDashboardTable,
} from "../../store/reducer/adminSlice";
import { useEditAdminMutation } from "../../store/action/adminApi";

const Table = ({ headers, data, renderAsHtml }) => {
  const dispatch = useDispatch();
  const editDashboardTable = useSelector(
    (state) => state.admin.editDashboardTable
  );
  const [editDashboard] = useEditAdminMutation();
  const headerToFieldObject = {
    "Patent Family": "family_mem",
    "Attorney Docket No.": "docket_num",
    Type: "ids_type",
    Date: "curr_date",
    Status: "status",
  };
  const handleEdit = async (rowId, header, value) => {
    const convertedHeader = headerToFieldObject[header];
    if (header === "Date") {
      const editedDate = new Date(value).toISOString();
      const editedDateObject = { [convertedHeader]: editedDate };
      await editDashboard({ id: rowId, data: editedDateObject });
      return;
    }
    const editObject = { [convertedHeader]: value };
    await editDashboard({ id: rowId, data: editObject });
  };
  return (
    <div className="table-container dashboard-table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  contentEditable={`${
                    editDashboardTable === rowIndex &&
                    header !== "Sr.No" &&
                    header !== "Action"
                      ? "true"
                      : "false"
                  }`}
                  suppressContentEditableWarning={true}
                  onBlur={(event) => {
                    const updatedValue = event.target.innerText;
                    handleEdit(row["Id_num"], header, updatedValue);
                  }}
                >
                  {header === "Action" ? (
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <img
                        src={TableEdit}
                        alt="Edit"
                        className="action-icon click-icon"
                        onClick={() => {
                          dispatch(setEditDashboardTable(rowIndex));
                        }}
                      />
                      <img
                        src={DashboardShare}
                        alt="Share"
                        className="ms-2 click-icon"
                        onClick={() => {
                          dispatch(setSharePopupToggle(true));
                        }}
                      />
                      <img
                        src={TableDownload}
                        alt="Download"
                        className="ms-2 click-icon"
                        onClick={() => {}}
                      />
                    </div>
                  ) : header === "Patent Family" ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: row[header] }}
                      className={`${
                        header === "Patent Family"
                          ? "family-dashboard-link"
                          : ""
                      }`}
                      onClick={() => {
                        if (header === "Patent Family") {
                          dispatch(setSelectIdsToggle(true));
                        }
                      }}
                    />
                  ) : (
                    row[header]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
