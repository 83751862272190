import React from "react";
import Button from "../../components/common/button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const ApplForm = (props) => {
  const { field1, field2, field3, field4 = "" } = props;
  return (
    <>
      <Form
        className={`form-class d-flex justify-content-between`}
        style={{
          margin:
            field1 === "Foreign Document Number" ||
            field1 === "Patent Number" ||
            field4 === "IDS"
              ? "auto"
              : "",
        }}
      >
        <Row className="row-container">
          <Col className="col-container">
            <Form.Label
              className={`${
                field1 === "Foreign Document Number" ? "foreign-label" : ""
              } ${field1 === "Application Number" ? "application-label" : ""}
              ${field1 === "Publication Number" ? "publication-label" : ""}`}
            >
              {field1}
            </Form.Label>
            <Form.Control
              className="ids-input"
              type="text"
              placeholder={field1}
            />
          </Col>
          <Col className="col-container">
            <Form.Label>{field2}</Form.Label>
            <Form.Control
              type="text"
              className="ids-input"
              placeholder={field2}
            />
          </Col>
          <Col className="col-container">
            <Form.Label className="label-width">{field3}</Form.Label>
            <Form.Control
              type="text"
              className="ids-input"
              placeholder={field3}
            />
          </Col>
          <Col className="add-btn-style col-btn-container">
            {field1 !== "Patent Number" ? (
              <Button className="add-btn" type="submit">
                +
              </Button>
            ) : (
              <Button className="add-btn" type="submit">
                X
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ApplForm;
