import React from "react";

const Input = ({ 
  type = "text", 
  className, 
  value, 
  onChange, 
  placeholder = "", 
  disabled = false,
  name,
}) => {
  return (
    <>
      <input
        name={name}
        type={type}
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </>
  );
};

export default Input;
