import React from 'react';
import { useSelector } from 'react-redux';
import Table from '../../common/table';
import './index.scss';
import { formatDate, PRIORITY_ORDER } from '../../../util/utils';

const SB08FormDetails = () => {
  const { familyBiblio } = useSelector((state) => state.family);

  const getHighestPriorityDocId = (docIds) => {
    if (!Array.isArray(docIds)) {
      return {};
    }
    const sortedDocIds = [...docIds].sort((a, b) => {
      const priorityA = PRIORITY_ORDER.indexOf(a['@document-id-type']);
      const priorityB = PRIORITY_ORDER.indexOf(b['@document-id-type']);
      return priorityA - priorityB;
    });
    return sortedDocIds[0] || {};
  };

  const familyMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member'];

  const familyMembersArray = Array.isArray(familyMembers) ? familyMembers : (familyMembers ? [familyMembers] : []);

  let tableIndex = 1;

  const formattedData = familyMembersArray.flatMap(member => {
    const referencesCited = member?.['exchange-document']?.['bibliographic-data']?.['references-cited'];
    if (!referencesCited || !Array.isArray(referencesCited['citation'])) {
      return [];
    }

    return referencesCited['citation'].map(citation => {
      const docIds = Array.isArray(citation.patcit?.['document-id']) ? citation.patcit?.['document-id'] : [];
      const docId = getHighestPriorityDocId(docIds);
      const dnumType = citation.patcit?.['@dnum-type'] || "";
      const country = docId?.['country']?.['$'] || "";
      const docNumber = docId?.['doc-number']?.['$'] || "";
      const kind = docId?.['kind']?.['$'] || "";
      const date = docId?.['date']?.['$'] || "";
      const publicationNumber = `${country}${docNumber}${kind}`;
      const publicationDate = formatDate(date);
      const publicationType = country === "US" ? "U.S.PATENT APPLICATION PUBLICATIONS" : "FOREIGN PATENT DOCUMENTS";
      const appDocId = docIds.find(id => id['@document-id-type'] === 'docdb');
      const applicationNumber = appDocId?.['doc-number']?.['$'] || "";
      const filingDate = formatDate(citation.patcit?.['application-date']?.[0]?.['date']?.[0] || "");
      const patenteeName = appDocId?.['name']?.['$'] || "";

      if (publicationNumber || kind || publicationDate || applicationNumber || filingDate || patenteeName || dnumType) {
        return {
          "Sr.No": tableIndex++,
          "References": publicationNumber,
          "Kind Code": kind,
          "Issue/Publication Date": publicationDate,
          "Patentee": patenteeName,
          "Type": publicationType,
        };
      }

      return null;
    }).filter(row => row !== null);
  });

  const headers = [
    "Sr.No",
    "References",
    "Kind Code",
    "Issue/Publication Date",
    "Patentee",
    "Type",
  ];

  const defaultRow = headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {});
  const data = Array.from({ length: 10 }, () => defaultRow);
  return (
    <div className="sb08">
      <Table headers={headers} data={formattedData.length > 0 ? formattedData : data} />
    </div>
  );
};

export default SB08FormDetails;
