// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ref-popup {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 30%;
  height: 32%;
}
.ref-popup img {
  padding: 3px;
}
.ref-popup .message {
  background-color: var(--btn_color);
  border-radius: 20px 20px 0 0;
  text-align: left;
  padding: 5px 10px;
  height: 30px;
  color: var(--primary_white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ref-popup .submessage {
  padding: 0px 20px 0px 20px;
}

.ref-popup .close-button {
  background: none;
  border: none;
  color: var(--primary_white);
  font-size: 16px;
  cursor: pointer;
  margin-left: auto;
}

.reference-popup-btn {
  width: 100px;
  height: 40px;
  gap: 0px;
  border-radius: 5px;
  border: 1px solid black;
  opacity: 0px;
  background: rgb(64, 85, 174);
  color: white;
}

.popup-no-btn {
  background: white;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/popup/reference-popup-index.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AAAF;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,kCAAA;EACA,4BAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,2BAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;;AAKA;EACE,0BAAA;AAFF;;AAKA;EACE,gBAAA;EACA,YAAA;EACA,2BAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAFF;;AAKA;EACE,YAAA;EACA,YAAA;EACA,QAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,4BAAA;EACA,YAAA;AAFF;;AAIA;EACE,iBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"../../index.css\";\n\n.popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.ref-popup {\n  background: white;\n  border-radius: 20px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  text-align: center;\n  width: 30%;\n  height: 32%;\n\n  img {\n    padding: 3px;\n  }\n\n  .message {\n    background-color: var(--btn_color);\n    border-radius: 20px 20px 0 0;\n    text-align: left;\n    padding: 5px 10px;\n    height: 30px;\n    color: var(--primary_white);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n}\n\n.ref-popup .submessage {\n  padding: 0px 20px 0px 20px;\n}\n\n.ref-popup .close-button {\n  background: none;\n  border: none;\n  color: var(--primary_white);\n  font-size: 16px;\n  cursor: pointer;\n  margin-left: auto;\n}\n\n.reference-popup-btn {\n  width: 100px;\n  height: 40px;\n  gap: 0px;\n  border-radius: 5px;\n  border: 1px solid black;\n  opacity: 0px;\n  background: rgba(64, 85, 174, 1);\n  color: white;\n}\n.popup-no-btn {\n  background: white;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
