const Form = ({ children, onSubmit, className, encType }) => (
  <form
    onSubmit={onSubmit}
    className={className}
    noValidate={true}
    autoComplete="off"
    encType={encType}
  >
    {children}
  </form>
);

export default Form;
