import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../common/button';
import Popup from '../popup/generate-form';
import RefPopup from '../popup/reference-popup'
import '../../pages/PatentView/patent-view.scss';
import { saveInitialReferences } from '../../store/reducer/initialReferencesSlice';
import { saveReferences } from '../../store/reducer/referencesSlice';
import { saveFamilyData } from '../../store/reducer/familySlice';

function ActionButtons({ activeTab, setActiveTab, extractedData, isRefPopupOpen, handleCloseRefPopup }) {
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { formattedInitialRefData } = useSelector((state) => state.search);
  const { formattedFamilyData } = useSelector((state) => state.family);
  const { formattedReferenceData } = useSelector((state) => state.references);
  const navigate = useNavigate();

  const tabs = ['initialReferences', 'familyInformation', 'references', 'sb08'];

  const handleGenerateClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleNoClick = async () => {
    handleCloseRefPopup();
  }
  const handleYesClick = async () => {
    handleCloseRefPopup();
    await handleDiscardSaveReferences()
  }

  const handleNextClick = async () => {
    try {
      if (activeTab === 'initialReferences') {
        await handleSaveInitialReferences();
        setActiveTab('familyInformation'); // Move to next tab after saving
      } else if (activeTab === 'familyInformation') {
        await handleSaveFamilyData();
        setActiveTab('references'); // Move to next tab after saving
      } else if (activeTab === 'references') {
        await handleSaveReferences();
        setActiveTab('sb08'); // Move to next tab after saving
      } else if (activeTab === 'sb08') {
        handleGenerateClick(); // Open popup for 'sb08'
      }
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };

  const handleBackClick = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };

  const getNextButtonText = () => {
    switch (activeTab) {
      case 'references':
        return 'Final IDS';
      case 'sb08':
        return 'Generate';
      default:
        return 'Save & Next';
    }
  };

  const isBackButtonVisible = () => {
    const firstTab = 'initialReferences';
    return activeTab !== firstTab;
  };

  const handleSaveInitialReferences = async () => {
    try {
      const initialReferencesData = formattedInitialRefData.map(item => {
        let pdf_ref_num = item["Publication / Application Number"] || "PlaceholderRefNum";
        if (!pdf_ref_num || typeof pdf_ref_num !== 'string') {
          pdf_ref_num = "PlaceholderRefNum";
        }

        let formattedDate = null;
        if (item['Issue / Publication Date']) {
          const date = new Date(item['Issue / Publication Date']);
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }

        return {
          pdf_ref_num,
          pdf_kind_code: item["Kind Code"] || "-",
          pdf_ip_date: formattedDate || null,
          pdf_patentee_name: item["Patentee"] || "-",
          pdf_type: item["Type"] || "-",
          pdf_string: '',
        };
      });

      await dispatch(saveInitialReferences(initialReferencesData)).unwrap();
    } catch (error) {
      console.error('Failed to save initial references:', error);
    }
  };
  const existsInitialInRef = (initRefData, refItem) => {
    return initRefData.some(item => item['Publication / Application Number'] === refItem['References'])
  }
  const handleDiscardSaveReferences = async () => {
    try {
      const ReferencesData = formattedReferenceData.filter(item => !existsInitialInRef(formattedInitialRefData, item)).map(item => {
        let ref_num = item["References"] || "PlaceholderRefNum";
        if (!ref_num || typeof ref_num !== 'string') {
          ref_num = "PlaceholderRefNum";
        }

        let formattedDate = null;
        if (item['Issued / Pub. Date']) {
          const date = new Date(item['Issued / Pub. Date']);
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }

        return {
          ref_num,
          kind_code: item["Kind Code"] || "-",
          ip_date: formattedDate || null,
          patentee_name: item["Patentee"] || "",
          string: "-",
          serial_num: item["Serial #"],
          flag: "",
        };
      });
      await dispatch(saveReferences(ReferencesData)).unwrap();
    } catch (error) {
      console.error('Failed to save references:', error);
    }
  };
  const handleSaveFamilyData = async () => {
    try {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date.toISOString() : null;
      };

      const familyDataToSave = formattedFamilyData.map(item => {
        return {
          serial_num: item['Serial #'],
          country_code: item['Country Code'],
          filing_date: formatDate(item['Filing Date']),
          status: item['Status'],
          pub_num: item['Pub. Number'],
          ip_date: formatDate(item['Pub. Date']),
          pat_num: item['Patent #'],
          patent_date: formatDate(item['Patent Date']),
          docket_num: item['Attorney Docket #'],
          applicants: item['Applicants'],
          priority_date: formatDate(item['Priority Date']),
          lastids_date: formatDate(item['Last IDS Filed Date']),
          ref_available: item['Availability'],
        };
      });

      await dispatch(saveFamilyData(familyDataToSave)).unwrap();
    } catch (error) {
      console.error('Failed to save family data:', error);
    }
  };

  const handleSaveReferences = async () => {
    try {
      const ReferencesData = formattedReferenceData.map(item => {
        let ref_num = item["References"] || "PlaceholderRefNum";
        if (!ref_num || typeof ref_num !== 'string') {
          ref_num = "PlaceholderRefNum";
        }

        let formattedDate = null;
        if (item['Issued / Pub. Date']) {
          const date = new Date(item['Issued / Pub. Date']);
          formattedDate = !isNaN(date.getTime()) ? date.toISOString() : null;
        }

        return {
          ref_num,
          kind_code: item["Kind Code"] || "-",
          ip_date: formattedDate || null,
          patentee_name: item["Patentee"] || "",
          string: "-",
          serial_num: item["Serial #"],
          flag: "",
        };
      });

      await dispatch(saveReferences(ReferencesData)).unwrap();
    } catch (error) {
      console.error('Failed to save references:', error);
    }
  };

  return (
    <div className='action-buttons d-flex'>
      {isBackButtonVisible() && (
        <Button className='back-btn' onClick={handleBackClick}>Back</Button>
      )}
      <Button className='next-btn' onClick={handleNextClick}>{getNextButtonText()}</Button>
      {isPopupOpen && (
        <Popup onClose={handleClosePopup} />
      )}
      {isRefPopupOpen && (
        <RefPopup onClose={handleCloseRefPopup}
          handleNoClick={handleNoClick}
          handleYesClick={handleYesClick} />
      )}
    </div>
  );
}

export default ActionButtons;
