import React from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.scss";
import FormBack from "../../assets/images/dashboard-back-button.png";
import SearchInForm from "../../assets/images/search-in-from.svg";
import Table from "../../components/common/dashboard-table";
import Button from "../../components/common/button";
function Dashboard() {
  const headers = [
    "Sr.No",
    "Patent Family",
    "Attorney Docket No.",
    "Type",
    "Date",
    "Email",
    "Status",
    "Action",
  ];
  const navigate = useNavigate();
  const defaultRow = headers.reduce(
    (acc, header) => ({ ...acc, [header]: "" }),
    {}
  );
  const handleClick = () => {
    navigate("/search");
  };
  const handleResumeClick = () => {
    navigate("/ids");
  };
  const defaultData = Array.from({ length: 12 }, () => defaultRow);
  return (
    <div className="dashboard-container">
      <div className="card card-2">
        <div className="tab-container d-flex">
          <div className="dashboard-title">Dashboard</div>
          <div className="icons">
            <Button
              className={"create-ids-btn"}
              children="Resume IDS"
              onClick={handleResumeClick}
            />
            <Button
              className={"create-ids-btn"}
              children="Create IDS"
              onClick={handleClick}
            />
            <img src={SearchInForm} alt="Search In Form" className="icon" />
            <img src={FormBack} alt="Form Back" className="icon" />
          </div>
        </div>
        <Table headers={headers} data={defaultData} />
      </div>
      <div className="d-flex flex-row login-stamp">
        <span className="login-stamp-text">Last Login Time stamp:</span>{" "}
        &nbsp;2020-12-25 10:30AM
      </div>
    </div>
  );
}

export default Dashboard;
