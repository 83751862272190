// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  width: 98%;
  overflow-x: auto;
  margin-left: 15px;
  height: auto;
  max-height: 60vh;
  min-height: 100px;
}
.table-container.dashboard-table-container {
  border-top: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .table-container {
    max-height: 50vh;
  }
}
.table-container .ref-avail-class {
  text-decoration: underline;
  color: #0000ee;
}
.table-container .ref-count {
  color: #0000ee;
  text-decoration: none;
}
.table-container .ref-arrow-img {
  width: 10px;
  height: 7px;
}
.table-container table {
  padding-left: 15px;
  width: 100%;
  border-collapse: collapse;
  background-color: var(--primary_white);
  border-spacing: 0;
}
.table-container table th,
.table-container table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  white-space: nowrap;
}
.table-container table .ref-title {
  font-weight: 600;
}
.table-container table .title-item-column {
  width: 300px;
}
.table-container table th {
  background-color: var(--btn_color);
  color: var(--primary_white);
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 2;
}
.table-container table tbody,
.table-container table th {
  font-size: 14px;
}
.table-container table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/components/common/index.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AACF;AAAE;EACE,eAAA;EACA,4BAAA;EACA,6BAAA;EACA,gBAAA;AAEJ;AAAE;EAbF;IAcI,gBAAA;EAGF;AACF;AAFE;EACE,0BAAA;EACA,cAAA;AAIJ;AAFE;EACE,cAAA;EACA,qBAAA;AAIJ;AAFE;EACE,WAAA;EACA,WAAA;AAIJ;AADE;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,sCAAA;EACA,iBAAA;AAGJ;AADI;;EAEE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AAGN;AADI;EACE,gBAAA;AAGN;AAAI;EACE,YAAA;AAEN;AACI;EACE,kCAAA;EACA,2BAAA;EACA,gBAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;AACN;AAEI;;EAEE,eAAA;AAAN;AAGI;EACE,yBAAA;AADN","sourcesContent":[".table-container {\n  width: 98%;\n  overflow-x: auto;\n  margin-left: 15px;\n  height: auto;\n  max-height: 60vh;\n  min-height: 100px;\n  &.dashboard-table-container {\n    border-top: 1px;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    margin-top: 10px;\n  }\n  @media (max-width: 768px) {\n    max-height: 50vh;\n  }\n  .ref-avail-class {\n    text-decoration: underline;\n    color: #0000ee;\n  }\n  .ref-count {\n    color: #0000ee;\n    text-decoration: none;\n  }\n  .ref-arrow-img {\n    width: 10px;\n    height: 7px;\n  }\n\n  table {\n    padding-left: 15px;\n    width: 100%;\n    border-collapse: collapse;\n    background-color: var(--primary_white);\n    border-spacing: 0;\n\n    th,\n    td {\n      padding: 10px;\n      border: 1px solid #ccc;\n      text-align: center;\n      white-space: nowrap;\n    }\n    .ref-title {\n      font-weight: 600;\n    }\n\n    .title-item-column {\n      width: 300px;\n    }\n\n    th {\n      background-color: var(--btn_color);\n      color: var(--primary_white);\n      font-weight: 600;\n      position: sticky;\n      top: 0;\n      z-index: 2;\n    }\n\n    tbody,\n    th {\n      font-size: 14px;\n    }\n\n    tbody tr:nth-child(odd) {\n      background-color: #f9f9f9;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
