import React from "react";
import Button from "../../components/common/button";
const AddBtn = () => {
  return (
    <Button className="add-btn add-new-btn" type="submit">
      Add
    </Button>
  );
};

export default AddBtn;
