// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-content {
  padding: 15px;
}
.summary-content .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
  color: var(--primary_black);
  padding-bottom: 20px;
}
.summary-content .row .key {
  font-weight: 600;
  margin-right: 20px;
  width: 270px;
  text-align: left;
}
.summary-content .row .value {
  text-align: left;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/patent-view/summary-details/summary.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,cAAA;EACA,2BAAA;EACA,oBAAA;AACN;AACM;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AACR;AAEM;EACE,gBAAA;EACA,SAAA;AAAR","sourcesContent":[".summary-content{\n    padding: 15px;\n\n    .row {\n      display: flex;\n      justify-content: flex-start;\n      align-items: center;\n      padding: 2px 0;\n      color: var(--primary_black);\n      padding-bottom: 20px;\n  \n      .key {\n        font-weight: 600;\n        margin-right: 20px; \n        width: 270px; \n        text-align: left;\n      }\n  \n      .value {\n        text-align: left;\n        flex: 1; \n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
