import React from "react";
import Button from "../common/button";
import Input from "../common/input";
import "./send-on-mail.scss";

const Popup = ({ onClose }) => {
  const RadioButton = ({ option }) => {
    return (
      <div className="d-flex flex-row align-items-center mx-2 radio-btn-container justify-content-center">
        <Input type="radio" name="format-btn" className="mx-2" />
        <label>{option}</label>
      </div>
    );
  };

  return (
    <div className="popup-overlay">
      <div className="send-on-mail-popup">
        <div className="message">
          <span>Send on mail</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="d-flex submessage py-2 flex-column align-items-start">
          <label for="email" className="mb-1">
            Email-id
          </label>
          <Input
            type="email"
            placeholder="Type a email id"
            className="py-2 email-input"
          />
          {/* <label for="file-format" className="py-2">
            Choose File Format
          </label>
          <div className="d-flex flex-row align-items-center justify-content-center mx-2 mb-2">
            <RadioButton option="PDF" />
            <RadioButton option="Word" />
            <RadioButton option="Excel" />
          </div> */}
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-1">
          <Button
            className={"send-on-mail-popup-btn mx-2"}
            children="Send"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
