import React from "react";
import Button from "../../components/common/button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Nonpatentform = (props) => {
  const { field1 } = props;
  return (
    <>
      <Form
        className="form-class nonpatentform-class d-flex justify-content-between"
        style={{
          margin: "auto",
        }}
      >
        <Row className="row-container">
          <Col className="col2-container">
            <Form.Control
              className="nonpatentids-input"
              type="text"
              placeholder={field1}
            />
          </Col>
          <Col className="col-btn-container">
            <Button className="add-btn" type="submit">
              X
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Nonpatentform;
