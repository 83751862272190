import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFamilyBiblioQuery } from "../../store/action/familyBiblioApi";
import { setFamilyBiblio, setLoading, setError } from '../../store/reducer/familySlice';
import InitialReferences from '../../components/patent-view/initial-ref/initial-ref';
import FamilyInformation from '../../components/patent-view/family-info-section/family-information';
import CitationMatrix from '../../components/patent-view/citation-matrix/citation-matrix';
import SB08FormDetails from '../../components/patent-view/SB08-details/details-tab';
import TableEdit from "../../assets/images/table-edit-icon.png"
import TableAdd from "../../assets/images/table-add-icon.png"
import TableDownload from "../../assets/images/table-download-icon.png"
import SearchInForm from '../../assets/images/search-in-from.svg';
import NplDetails from '../../components/patent-view/npl-info-section/npl-info';
import SummaryDetails from '../../components/patent-view/summary-details/summary';
import ActionButtons from '../../components/patent-view/action-buttons';
import TabButton from '../../components/common/tab-button';
import TopContent from '../../components/patent-view/top-content';
import './patent-view.scss';
import Button from '../../components/common/button';
import RefPopup from '../../components/popup/reference-popup'
import { saveReferences } from '../../store/reducer/referencesSlice';

function PatentView() {
  const dispatch = useDispatch();
  const searchParams = useSelector((state) => state.search);
  const { data, error, isLoading } = useGetFamilyBiblioQuery({ type: searchParams.type, format: 'docdb', number: searchParams.number });
  const extractedData = useSelector((state) => state.search.extractedData);
  const [isRefPopupOpen, setIsRefPopupOpen] = useState(false);
  const [downloadReferences, setDownloadRef] = useState(false);

  const handleDownloadRef = () => {
    setDownloadRef(true);
    setTimeout(() => setDownloadRef(false), 1000)
  }
  useEffect(() => {
    if (data) {
      dispatch(setFamilyBiblio(data));
    }
    if (error) {
      console.error('Error fetching data:', error);
      dispatch(setError(error));
    }
    if (isLoading) {
      dispatch(setLoading());
    }
  }, [data, error, isLoading, dispatch]);

  const [activeTab, setActiveTab] = useState('familyInformation');

  useEffect(() => {
    if (extractedData.length > 0) {
      setActiveTab('initialReferences');
    }
  }, [extractedData]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleRefPopup = () => {
    if (extractedData.length > 0) {
      setIsRefPopupOpen(true);
      return;
    }
  }
  const handleCloseRefPopup = () => {
    setIsRefPopupOpen(false);
  };

  return (
    <div className="patent-view-container">
      <div className={`top-summary-container ${activeTab === 'initialReferences' ? 'full-width-top' : ''}`}>
        <div className={`card card-1 top-content ${activeTab === 'initialReferences' ? 'full-width' : ''}`}>
          <TopContent />
        </div>
        {activeTab !== 'initialReferences' && (
          <div className="card card-1 summary">
            <SummaryDetails />
          </div>
        )}
      </div>
      <div className={`card card-2 ${activeTab === 'familyInformation' ? 'family-tab' : 'other-tabs'}`}>
        <div className="tab-container">
          {extractedData.length > 0 && (
            <TabButton isActive={activeTab === 'initialReferences'} onClick={() => handleTabClick('initialReferences')}>
              Initial References
            </TabButton>
          )}
          <TabButton isActive={activeTab === 'familyInformation'} onClick={() => handleTabClick('familyInformation')}>
            Family
          </TabButton>
          {/* <TabButton isActive={activeTab === 'citationMatrix'} onClick={() => handleTabClick('citationMatrix')}>
            Family Citations
          </TabButton> */}
          <TabButton isActive={activeTab === 'references'} onClick={() => handleTabClick('references')}>
            References
          </TabButton>
          <TabButton isActive={activeTab === 'sb08'} onClick={() => handleTabClick('sb08')}>
            IDS
          </TabButton>
          <div className='d-flex flex-row legend-icon-container'>
            {activeTab !== 'initialReferences' && activeTab !== 'familyInformation' && extractedData.length > 0 && (
              <Button className="discard-btn mx-3" children="Discard Initial References" onClick={handleRefPopup} />
            )}
            <div className="icons">
              <img src={SearchInForm} alt="Search In Form" className="icon" />
              <img src={TableEdit} alt="Table Edit" className="icon" />
              <img src={TableAdd} alt="Table Add" className="icon" />
              {activeTab === 'references' && <img src={TableDownload} alt="Download References" className='icon' onClick={handleDownloadRef} />}
            </div>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 'initialReferences' && extractedData.length > 0 && <InitialReferences />}
          {activeTab === 'familyInformation' && <FamilyInformation />}
          {activeTab === 'references' && <CitationMatrix downloadReferences={downloadReferences} />}
          {activeTab === 'sb08' && <SB08FormDetails />}
        </div>
        <div className='npl-info'>
          {activeTab !== 'familyInformation' && (
            <div className="card-2">
              <NplDetails activeTab={activeTab} />
            </div>
          )}
        </div>
        <div className='action-buttons-container'>
          <ActionButtons activeTab={activeTab} setActiveTab={setActiveTab} extractedData={extractedData} isRefPopupOpen={isRefPopupOpen} handleCloseRefPopup={handleCloseRefPopup} />
        </div>
      </div>

    </div>

  );
}

export default PatentView;
