import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../common/npl-table";
import "./npl-details.scss";
import {
  setExtractedNplData,
  setFamilyNplData,
} from "../../../store/reducer/searchSlice";
const removeTrailingComma = (str) => str.replace(/,\s*$/, "");

const formatNplString = (str) => {
  const authorMatch = str.match(
    /^([A-Z][A-Z\s,]*(?:and\s[A-Z][A-Z\s,]*|et\s+al\.)?)/
  );
  const titleMatch = str.match(/"([^"]*)"/);
  const additionalTitleMatch = str.match(/(?:^|\s)([^,]+)(?:,|$)/);
  const titleOfItemMatch = str.match(
    /^(?:[^"]*"[^"]*")?\s*(.*?)(?=,\s*(?:pp\.|pages?\.\d+-\d+))/i
  );
  const dateMatch = str.match(
    /(?:\b(?:\d{4}|(?:[A-Za-z]+\s\d{1,2},\s\d{4})|(?:[A-Za-z]+\s\d{4})|(?:\d{1,2}\s\(\w{4}\))|(?:\d{4}))\b)/
  );
  const pagesMatch = str.match(/(?:pages?\.\s*|pp\.\s*)(\d+-\d+)/);
  const linkMatch = str.match(/(https?:\/\/[^\s]+)/);
  const publisherMatch = str.match(
    /(?:^|\s)(?:Department|University|Press|Publication|Journal|Book|Conference|Symposium|Serial|Catalog|Publisher|City|Country|Etc)[^,]*,\s*([A-Za-z\s]+(?:,\s*[A-Z]{2})?)\b/i
  );

  return {
    "Author Name":
      authorMatch && authorMatch[1].trim().length > 1
        ? removeTrailingComma(authorMatch[1].trim())
        : "Not Available",
    Title: titleMatch
      ? removeTrailingComma(titleMatch[1])
      : additionalTitleMatch
        ? removeTrailingComma(additionalTitleMatch[1])
        : "",
    "Title of the Item": titleOfItemMatch ? titleOfItemMatch[1].trim() : "",
    Date: dateMatch ? dateMatch[0].replace(/[()]/g, "") : "",
    Pages: pagesMatch ? pagesMatch[1] : "",
    Volume: "",
    Publisher: publisherMatch ? publisherMatch[0].trim() : "",
    Link: linkMatch
      ? `<a href="${linkMatch[0]}" target="_blank" rel="noopener noreferrer">${linkMatch[0]}</a>`
      : "Not Available",
  };
};

const NplDetails = ({ activeTab }) => {
  const { familyBiblio } = useSelector((state) => state.family);
  const { extractedData: dataTable } = useSelector((state) => state.search);
  const discardedNplData = useSelector((state) => state.search.discardedNplData);
  const finalDiscardedNplData = discardedNplData.length === 0 ? [] : discardedNplData;
  const selectedNplData = useSelector((state) => state.search.selectedNplData);
  const [selectedAuthors, setSelectedAuthors] = useState({});
  const dispatch = useDispatch();
  const familyMembers =
    familyBiblio?.["ops:world-patent-data"]?.["ops:patent-family"]?.[
    "ops:family-member"
    ] || [];
  const familyMembersArray = Array.isArray(familyMembers)
    ? familyMembers
    : [familyMembers];

  const nplStringsFromFamily = familyMembersArray.flatMap((member) => {
    const referencesCited =
      member?.["exchange-document"]?.["bibliographic-data"]?.[
      "references-cited"
      ];
    if (!referencesCited || !Array.isArray(referencesCited.citation)) {
      return [];
    }

    return referencesCited.citation.flatMap((citation) => {
      if (citation?.nplcit) {
        return citation.nplcit.text?.["$"] ? [citation.nplcit.text["$"]] : [];
      }
      return [];
    });
  });

  const nplStringsFromExtractedData = (dataTable || []).flatMap((row) => {
    const type =
      row?.["uscom:TableRow"][0]?.["uscom:TableHeaderCell"]?.[
      "uscom:Heading"
      ] || "";
    if (type === "NON-PATENT LITERATURE DOCUMENTS") {
      return row["uscom:TableRow"].slice(2).flatMap((dataRow) => {
        const dataCells = dataRow?.["uscom:TableDataCell"] || [];
        if (dataCells.length > 2 && dataCells[2]?.["uscom:TextInput"]) {
          return [dataCells[2]["uscom:TextInput"]];
        }
        return [];
      });
    }
    return [];
  });

  const formattedFamilyData = nplStringsFromFamily.map(formatNplString);
  const formattedExtractedData =
    nplStringsFromExtractedData.map(formatNplString);

  const handleCheckboxChange = (authorName) => {
    setSelectedAuthors((prevState) => ({
      ...prevState,
      [authorName]: !prevState[authorName],
    }));
  };

  const addCheckboxToAuthorName = (formattedData) => {
    return formattedData.map((row) => ({
      ...row,
      "Author Name": (
        <label className="author-checkbox-label">
          <input
            type="checkbox"
            checked={selectedAuthors[row["Author Name"]] || false}
            onChange={() => handleCheckboxChange(row["Author Name"])}
          />
          <span>{row["Author Name"]}</span>
        </label>
      ),
    }));
  };

  const headers = [
    "Sr.No",
    "Author Name",
    "Title",
    "Title of the Item",
    "Date",
    "Pages",
    "Volume",
    "Publisher",
    "Link",
  ];

  const defaultRow = headers.reduce(
    (acc, header) => ({ ...acc, [header]: "" }),
    {}
  );

  let data;

  if (activeTab === "initialReferences") {
    data = formattedExtractedData.length > 0 ? formattedExtractedData : [];
  } else if (activeTab === "references") {
    data = formattedFamilyData.length > 0 ? formattedFamilyData : [];
  } else {
    data = finalDiscardedNplData.length > 0 ? finalDiscardedNplData : selectedNplData.length !== 0 ? selectedNplData : formattedFamilyData;
  }
  const combinedData = data.map((item, index) => ({
    ...item,
    "Sr.No": index + 1,
    Selected: false,
  }));
  useEffect(() => {
    dispatch(setExtractedNplData(formattedExtractedData));
  }, [dataTable, dispatch]);
  useEffect(() => {
    dispatch(setFamilyNplData(formattedFamilyData));
  }, [familyBiblio, dispatch]);

  return (
    <div className="details-container">
      {data.length === 0 ? <></> : <> <div className="label">Non-Patent Literature Documents</div>
        <div className="data-list">
          <Table
            headers={headers}
            formattedData={combinedData}
            renderAsHtml
            activeTab={activeTab}
          />
        </div></>}
    </div>
  );
};

export default NplDetails;
