import React, { useState, useEffect } from 'react';
import Upload from '../../assets/images/upload.svg';
import Input from '../common/input';
import XMLDataExtractor from '../../utils/dataExtractor/xmlDataExtractor';
import ExcelDataExtractor from '../../utils/dataExtractor/excelDataExtractor';
import './upload-section.scss';
import { useDispatch } from 'react-redux';
import { setExtractedExcelData } from '../../store/reducer/searchSlice';
import { setDocketNumber } from '../../store/reducer/searchSlice';
import Dropdown from "../../components/common/dropdown";
import PlusIcon from "../../assets/images/intial-ids-plus-icon.png"


const UploadSection = ({ selectedRadio, setSelectedRadio, buttonText, setButtonText, handleUploadFamilyMember, handleAddFamilyMember, handleDockNo, handleUploadFileNo, isDockInputValid, isFamValid, isAddFamValid, isFileNoValid, selectedFamOptionValid, handleFamSelectedOption, handleFamValid }) => {

  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [docketInput, setDocketInput] = useState('');
  const [familyMember, setFamilyMember] = useState('');
  const [familyMemberOption, setFamilyMemberOption] = useState('publication');
  const [addFamilyMember, setAddFamilyMember] = useState('')
  const dropdownOptions = [
    { value: "application", label: "Application #", disabled: false },
    { value: "publication", label: "Publication #", disabled: false },
    { value: "patent", label: "Patent #", disabled: false },
    { value: "pct", label: "PCT #", disabled: true },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedRadio === 'option1') {
      setButtonText('Upload Patent as Prior Art (upload CSV or excel file)');
    } else {
      setButtonText('Upload Initial IDS (Upload PDF or XML File)');
    }
  }, [selectedRadio, setButtonText]);

  useEffect(() => {
    handleUploadFamilyMember(familyMember)
  }, [familyMember])

  useEffect(() => {
    handleAddFamilyMember(addFamilyMember)
  }, [addFamilyMember])
  useEffect(() => {
    handleDockNo(docketInput)
  }, [docketInput])
  useEffect(() => {
    handleUploadFileNo(files)
  }, [files])
  useEffect(() => {
    handleFamSelectedOption(familyMemberOption)
  }, [familyMemberOption])

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    setFiles([]);
    setErrorMessage('');
  };

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);
    let validFiles = [];
    let invalidFiles = [];
    filesArray.forEach((file) => {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (
        (selectedRadio === 'option1' && (fileType === 'text/csv' || fileExtension === 'xlsx' || fileExtension === 'xls')) ||
        (selectedRadio === 'option2' && (fileType === 'application/pdf' || fileExtension === 'xml'))
      ) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      setErrorMessage(`Invalid file types: ${invalidFiles.join(', ')}`);
    } else {
      setErrorMessage('');
    }

    if (selectedRadio === 'option1' && validFiles.length > 1) {
      setErrorMessage('Only one file is allowed for Initial IDS.');
      setFiles([]);
    } else {
      setFiles(validFiles);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
    setFiles(updatedFiles);
  };

  const handleDocketNumberChange = (e) => {
    const value = e.target.value;
    setDocketInput(value);
    dispatch(setDocketNumber(value));
  };
  const handleFamilyInputChange = (event) => {
    const value = event.target.value;
    setFamilyMember(value);

    // Validate input format: XXXXXXX or XXYYYYY or XXXXXXX[A-Z][0-9] (e.g., RE12345, AB12345, US9834412B2)
    const isValidFormat =
      /^[0-9]{7,}$/.test(value) ||
      /^[A-Z]{2}[0-9]{5,}$/.test(value) ||
      /^[A-Z]{2}[0-9]{7}[A-Z][0-9]$/.test(value);

    handleFamValid(isValidFormat);
  };
  const renderButtonTextPrior = () => {
    return (
      <span className='d-flex flex-column prior-art-text'>
        Upload Patent as Prior Art <span className="file-type-prior-text">(Upload CSV or Excel File)</span>
      </span>
    );
  }
  const renderButtonText = () => {
    if (selectedRadio === 'option1') {
      return (
        <span className='d-flex flex-column prior-art-text'>
          Upload Patent as NPL <span className="file-type-prior-text">(Upload CSV or Excel File)</span>
        </span>
      );
    }
    return (
      <>
        Upload Initial IDS <span className="file-type-text">(Upload PDF or XML File)</span>
      </>
    );
  };
  const ExcelDataExtractorFunction = (file) => {
    ExcelDataExtractor(file).then((references) => {
      dispatch(setExtractedExcelData(references))
    })
  }

  return (
    <div className="button-container">
      <div className="radio-buttons">
        <label>
          <Input
            type="radio"
            name="idsType"
            value="option1"
            checked={selectedRadio === 'option1'}
            onChange={handleRadioChange}
          />
          Initial IDS
          <span className="info-icon icon-prior" data-tooltip="First Information Disclosure Statement filed for specific patent application.">i</span>
        </label>
        <label>
          <Input
            type="radio"
            name="idsType"
            value="option2"
            checked={selectedRadio === 'option2'}
            onChange={handleRadioChange}
          />
          Supplementary IDS
          <span className="info-icon icon-prior" data-tooltip="Additional Information Disclosure Statement filed for specific patent application.">i</span>
        </label>
      </div>
      {selectedRadio && (
        <>
          <div className='d-flex flex-row align-items-center' style={{ width: "100%" }}>
            <div className={`button-container ${selectedRadio === "option1" ? 'button-container-prior' : ''} pt-1`}>
              <div className="upload-btn-wrapper">
                <button className={`upload-btn ${selectedRadio === "option1" ? 'upload-btn-prior' : ''} ${!isFileNoValid && selectedRadio === "option1" ? "error-btn-border" : ""}`}>
                  <img src={Upload} alt="Upload" className={`${selectedRadio === "option2" ? 'icon' : ''}`} />
                  {renderButtonText()}
                </button>
                <input
                  type="file"
                  accept={selectedRadio === 'option1' ? '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' : '.pdf,.xml'}
                  multiple={selectedRadio === 'option2'}
                  onChange={selectedRadio === "option2" ? handleFileChange : () => { }}
                />
              </div>
              {selectedRadio === "option1" && (<div className="upload-btn-wrapper">
                <button className={`upload-btn ${selectedRadio === "option1" ? 'upload-btn-prior' : ''}
                ${!isFileNoValid && selectedRadio === "option1" ? "error-btn-border" : ""}`}>
                  <img src={Upload} alt="Upload" className="" />
                  {renderButtonTextPrior()}
                </button>
                <input
                  type="file"
                  accept={selectedRadio === 'option1' ? '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' : '.pdf,.xml'}
                  multiple={selectedRadio === 'option2'}
                  onChange={handleFileChange}
                />
              </div>)}
            </div>
            {selectedRadio === "option1" && <span className="info-icon icon-prior" data-tooltip="Please upload a Single-Column File">i</span>}
            {selectedRadio === "option2" && <img src={PlusIcon} className='plus-icon mb-2' alt="Plus Icon" />}
          </div>
          {files.length > 0 && (
            <div className="uploaded-files mt-1 mb-2" style={{ height: selectedRadio === 'option1' ? '30px' : '80px' }}>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <span className="remove-file" onClick={() => handleRemoveFile(index)}>x</span>
                  </li>
                ))}
              </ul>
              {selectedRadio === 'option1' && files.length === 1 ? ExcelDataExtractorFunction(files[0]) : (<XMLDataExtractor files={files} />)}
            </div>
          )}
          {selectedRadio === 'option1' && (
            <div className="initial-ids-inputs">
              <div className="docket-number-container mb-2 d-flex">
                <label className="label">Docket Number</label>
                <Input
                  type="text"
                  className={`docket-no form-control ${!isDockInputValid ? "is-invalid" : ""}`}
                  placeholder="Type Attorney Docket Number"
                  value={docketInput}
                  onChange={handleDocketNumberChange}
                />
              </div>
              <div className="family-member-container mb-2">
                <label className="label">Enter Corresponding Family Member
                  <span className="info-icon icon-prior" data-tooltip="Please enter Anyone Family Member">i</span>
                </label>

                <div className='dropdown-input-container mb-3'>
                  <Dropdown
                    options={dropdownOptions}
                    className={`application-no dropdown-select form-control ${!selectedFamOptionValid ? "is-invalid" : ""
                      }`}
                    value={familyMemberOption}
                    onChange={(e) => setFamilyMemberOption(e.target.value)}
                    required
                  />
                  <Input
                    type="text"
                    className={`form-control family-placeholder ${!isFamValid ? "is-invalid" : ""}`}
                    placeholder="Enter corresponding Family member"
                    value={familyMember}
                    onChange={handleFamilyInputChange}
                  />
                </div>
                <label className="label">Enter Additional References
                  <span className="info-icon icon-prior" data-tooltip="Please enter Additional Family Member separated by a comma">i</span>
                </label>
                <Input
                  type="text"
                  className={`form-control ${!isAddFamValid ? "is-invalid" : ""}`}
                  placeholder="Enter Additional References"
                  value={addFamilyMember}
                  onChange={(e) => setAddFamilyMember(e.target.value)}
                />
              </div>
            </div>
          )}
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default UploadSection;
