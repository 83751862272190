import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { buildMutationHelper } from "../../buildMutationHelper";
import { BASE_URL } from "../../utils/constants";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),

  endpoints: (builder) => ({
    login: buildMutationHelper(
      builder,
      (getData) => ({
        method: "POST",
        url: `auth/login`,
        body: getData,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      (response, dispatch) => {
        console.log(response);
      },
      ({ error }, dispatch) => {
        console.log(error);
      }
    ),
  }),
});

export const { useLoginMutation } = loginApi;
export default loginApi;
