import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const referencesApi = createApi({
  reducerPath: "referencesApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createReference: builder.mutation({
      query: (data) => ({
        url: 'references',
        method: 'POST',
        body: data,
      }),
    }),
    getSavedReferences: builder.query({
      query: () => `references`,
    }),
  }),
});

export const { useCreateReferenceMutation, useGetSavedReferencesQuery } = referencesApi;
export default referencesApi;

