import React from "react";
import ApplicationForm from "./applform";
import AddBtn from "./addBtn";
import Nonpatentform from "./nonpatentform";
import Examinerform from "./examinerform";
import Signatureform from "./signatureform";
import Button from "../../components/common/button";
import { useNavigate } from "react-router-dom";
import "./idsform.scss";

const Ids_modal = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/patent-view");
  };
  return (
    <div className="container-md form-modal">
      <span className="back-btn-container d-flex">
        <Button className="back-btn" onClick={handleClick}>
          Back
        </Button>
      </span>
      <div className="title-container d-grid">
        <span className="top-header1">
          Doc code: IDS <br></br>Doc description: Information Disclosure
          Statement (IDS) Filed
        </span>
        <span className="top-header">
          PTO/SB/08a (01-10)<br></br> Approved for use through 07/31/2012. OMB
          0651-0031<br></br>
          U.S. Patent and Trademark Office; U.S. DEPARTMENT OF COMMERCE
          <br></br> Under the Paperwork Reduction Act of 1995, no persons are
          required to respond to a collection of information unless it contains
          a valid OMB control number.
        </span>
        <div className="info-heading-container d-grid ">
          <span className="ids-form-heading info-disclosure ">
            INFORMATION DISCLOSURE STATEMENT BY APPLICANT
          </span>
        </div>
        <span className="not-for-sub">
          ( Not for submission under 37 CFR 1.99)
        </span>
        <span className="title-formfield d-flex">
          <ApplicationForm
            field1="Application Number"
            field2="Filing Date"
            field3="First Named Inventor"
          />
        </span>
      </div>
      <div className="issuedate-container d-grid">
        <span className="issuedate-formfield d-flex">
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
          <ApplicationForm
            field1="Patent Number"
            field2="Issue Date"
            field3="Name of Patentee or Applicant of cited Document"
          />
        </span>
        <span className="add-btn-formfield d-flex">
          <AddBtn />
        </span>
      </div>
      <div className="uspatent-container d-grid ">
        <div className="d-grid">
          <span className="ids-form-heading">
            U.S.PATENT APPLICATION PUBLICATIONS
          </span>
          <span className="uspatent-formfield d-flex">
            <ApplicationForm
              field1="Publication Number"
              field2="Publication Date"
              field3="Name of Patentee or Applicant of cited Document"
            />
            <ApplicationForm
              field1="Publication Number"
              field2="Publication Date"
              field3="Name of Patentee or Applicant of cited Document"
            />
            <ApplicationForm
              field1="Publication Number"
              field2="Publication Date"
              field3="Name of Patentee or Applicant of cited Document"
            />
            <ApplicationForm
              field1="Publication Number"
              field2="Publication Date"
              field3="Name of Patentee or Applicant of cited Document"
            />
          </span>
          <span className="add-btn-formfield d-flex">
            <AddBtn />
          </span>
        </div>
      </div>
      <div className="foreignpatent-container d-grid">
        <div className="d-grid">
          <span className="ids-form-heading">FOREIGN PATENT DOCUMENTS</span>
          <span className="foreignpatent-formfield d-flex">
            <ApplicationForm
              field1="Foreign Document Number"
              field2="Country Code"
              field3="Name of Patentee or Applicant of cited Document"
            />
          </span>
          <span className="add-btn-formfield d-flex">
            <AddBtn />
          </span>
        </div>
      </div>
      <div className="nonpatent-container d-grid">
        <div className="d-grid">
          <span className="ids-form-heading">
            NON-PATENT LITERATURE DOCUMENTS
          </span>
          <span className="nonpatent-header">
            Include name of the author (in CAPITAL LETTERS), title of the
            article (when appropriate), title of the item (book, magazine,
            journal, serial,<br></br>symposium, catalog, etc), date, pages(s),
            volume-issue number(s), publisher, city and/or country where
            published.
          </span>
          <span className="nonpatent-formfield d-flex ">
            <Nonpatentform field1="International search report and written opininon for PCT/US2015/056705 mailed dated dec. 29, 2015, 5pages." />
            <Nonpatentform field1="International search report and written opininon for PCT/US2015/056705 mailed dated dec. 29, 2015, 5pages." />
          </span>
          <span className="add-btn-formfield d-flex">
            <AddBtn />
          </span>
        </div>
      </div>
      <div className="examiner-container d-grid">
        <div className="d-grid">
          <span className="ids-form-heading1">EXAMINER SIGNATURE</span>
          <span className="d-flex examformfield">
            <Examinerform
              field1="Examiner Signature"
              field2="Date Considered"
            />
          </span>
        </div>
      </div>
      <div className="title-container1 d-grid">
        <div className="d-grid">
          <span className="ids-form-heading ">
            INFORMATION DISCLOSURE STATEMENT BY APPLICANT
          </span>
          <span className="not-for-sub">
            ( Not for submission under 37 CFR 1.99)
          </span>
          <span className="infod-form-field">
            <ApplicationForm
              field1="Application Number"
              field2="Filing Date"
              field3="First Named Inventor"
              field4="IDS"
            />
          </span>
        </div>
      </div>
      <div className="certificate-container d-grid">
        <div className="d-flex certificate-wrapper">
          <span className="ids-form-heading">
            CERTIFICATION STATEMENT<br></br>
            <span className="certificate-header">
              Please see 37 CFR 1.97 and 1.98 to make the appropriate
              selection(s):
            </span>
          </span>
          <span className="certificate-points d-flex">
            <span className="d-flex point-container">
              <input type="checkbox" className="certificate checkbox" />
              <span style={{ "margin-left": "5px" }}>
                That each item of information contained in the information
                disclosure statement was first cited in any communication from a
                foreign patent office in a counterpart foreign application not
                more than three months prior to the filing of the information
                disclosure statement. See 37 CFR 1.97(e)(1).
              </span>
            </span>
            <span className="or-style">OR</span>
            <span className="d-flex point-container-or-2">
              <input type="checkbox" className="certificate checkbox" />
              <span style={{ "margin-left": "5px" }}>
                That no item of information contained in the information
                disclosure statement was cited in a communication from a foreign
                patent office in a counterpart foreign application, and, to the
                knowledge of the person signing the certification after making
                reasonable inquiry, no item of information contained in the
                information disclosure statement was known to any individual
                designated in 37 CFR 1.56(c) more than three months prior to the
                filing of the information disclosure statement. See 37 CFR
                1.97(e)(2).
              </span>
            </span>
            <span className="d-flex point-container-3">
              <input type="checkbox" className="certificate checkbox" />
              <span style={{ "margin-left": "5px" }}>
                See attached certification statement.
              </span>
            </span>
            <span className="d-flex point-container-3">
              <input type="checkbox" className="certificate checkbox" />
              <span style={{ "margin-left": "5px" }}>
                The fee set forth in 37 CFR 1.17 (p) has been submitted
                herewith.
              </span>
            </span>
            <span className="d-flex point-container-3">
              <input type="checkbox" className="certificate checkbox" />
              <span style={{ "margin-left": "5px" }}>
                A certification statement is not submitted herewith.
              </span>
            </span>
          </span>
          <div className="signature">SIGNATURE</div>
          <div className="signature-header">
            A signature of the applicant or representative is required in
            accordance with CFR 1.33, 10.18. Please see CFR 1.4(d) for the form
            of the signature.
          </div>
          <div className="d-flex signatureform-field">
            <Signatureform field1="Signature" field2="Date (YYYY-MM-DD)" />
            <Signatureform field1="Name/Print" field2="Registration Number" />
          </div>
          <div className="d-flex collection-wrapper">
            <div>
              This collection of information is required by 37 CFR 1.97 and
              1.98. The information is required to obtain or retain a benefit by
              the public which is to file (and by the USPTO to process) an
              application. Confidentiality is governed by 35 U.S.C. 122 and 37
              CFR 1.14. This collection is estimated to take 1 hour to complete,
              including gathering, preparing and submitting the completed
              application form to the USPTO. Time will vary depending upon the
              individual case. Any comments on the amount of time you require to
              complete this form and/or suggestions for reducing this burden,
              should be sent to the Chief Information Officer, U.S. Patent and
              Trademark Office, U.S. Department of Commerce, P.O. Box 1450,
              Alexandria, VA 22313-1450. DO NOT SEND FEES OR COMPLETED FORMS TO
              THIS ADDRESS. SEND TO: Commissioner for Patents, P.O. Box 1450,
              Alexandria, VA 22313-1450.
            </div>
            <div>
              The Privacy Act of 1974 (P.L. 93-579) requires that you be given
              certain information in connection with your submission of the
              attached form related to a patent application or patent.
              Accordingly, pursuant to the requirements of the Act, please be
              advised that: (1) the general authority for the collection of this
              information is 35 U.S.C. 2(b)(2); (2) furnishing of the
              information solicited is voluntary; and (3) the principal purpose
              for which the information is used by the U.S. Patent and Trademark
              Office is to process and/or examine your submission related to a
              patent application or patent. If you do not furnish the requested
              information, the U.S. Patent and Trademark Office may not be able
              to process and/or examine your submission, which may result in
              termination of proceedings or abandonment of the application or
              expiration of the patent.
            </div>
          </div>
          <div className="d-flex justify-space-between routine-header">
            The information provided by you in this form will be subject to the
            following routine uses:
          </div>
          <div className="d-flex end-points-wrapper">
            <div>
              1.The information on this form will be treated confidentially to
              the extent allowed under the Freedom of Information Act (5 U.S.C.
              552) and the Privacy Act (5 U.S.C. 552a). Records from this system
              of records may be disclosed to the Department of Justice to
              determine whether the Freedom of Information Act requires
              disclosure of these record s.
            </div>
            <div>
              2.A record from this system of records may be disclosed, as a
              routine use, in the course of presenting evidence to a court,
              magistrate, or administrative tribunal, including disclosures to
              opposing counsel in the course of settlement negotiations.{" "}
            </div>
            <div>
              3.A record in this system of records may be disclosed, as a
              routine use, to a Member of Congress submitting a request
              involving an individual, to whom the record pertains, when the
              individual has requested assistance from the Member with respect
              to the subject matter of the record.{" "}
            </div>
            <div>
              4.A record in this system of records may be disclosed, as a
              routine use, to a contractor of the Agency having need for the
              information in order to perform a contract. Recipients of
              information shall be required to comply with the requirements of
              the Privacy Act of 1974, as amended, pursuant to 5 U.S.C. 552a(m).{" "}
            </div>
            <div>
              5.A record related to an International Application filed under the
              Patent Cooperation Treaty in this system of records may be
              disclosed, as a routine use, to the International Bureau of the
              World Intellectual Property Organization, pursuant to the Patent
              Cooperation Treaty.{" "}
            </div>
            <div>
              6.A record in this system of records may be disclosed, as a
              routine use, to another federal agency for purposes of National
              Security review (35 U.S.C. 181) and for review pursuant to the
              Atomic Energy Act (42 U.S.C. 218(c)).{" "}
            </div>
            <div>
              7.A record from this system of records may be disclosed, as a
              routine use, to the Administrator, General Services, or his/her
              designee, during an inspection of records conducted by GSA as part
              of that agency's responsibility to recommend improvements in
              records management practices and programs, under authority of 44
              U.S.C. 2904 and 2906. Such disclosure shall be made in accordance
              with the GSA regulations governing inspection of records for this
              purpose, and any other relevant (i.e., GSA or Commerce) directive.
              Such disclosure shall not be used to make determinations about
              individuals.{" "}
            </div>
            <div>
              8.A record from this system of records may be disclosed, as a
              routine use, to the public after either publication of the
              application pursuant to 35 U.S.C. 122(b) or issuance of a patent
              pursuant to 35 U.S.C. 151. Further, a record may be disclosed,
              subject to the limitations of 37 CFR 1.14, as a routine use, to
              the public if the record was filed in an application which became
              abandoned or in which the proceedings were terminated and which
              application is referenced by either a published application, an
              application open to public inspections or an issued patent.{" "}
            </div>
            <div style={{ "padding-bottom": "60px" }}>
              9. A record from this system of records may be disclosed, as a
              routine use, to a Federal, State, or local law enforcement agency,
              if the USPTO becomes aware of a violation or potential violation
              of law or regulation.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ids_modal;
