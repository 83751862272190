import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: ({ userEmail }) => `users/${userEmail}`
        }),
    })
})
export const { useGetUserProfileQuery } = userApi
export default userApi