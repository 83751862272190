import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/constants";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createAdmin: builder.mutation({
      query: (data) => ({
        url: 'admin',
        method: 'POST',
        body: data,
      }),
    }),
    getAdmins: builder.query({
      query: () => `admin`,
      keepUnusedDataFor: 0
    }),
    updateStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `admin/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    editAdmin: builder.mutation({
      query: ({ id, data }) => ({
        url: `admin/edit/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const { useCreateAdminMutation, useGetAdminsQuery, useUpdateStatusMutation, useEditAdminMutation } = adminApi;
export default adminApi;
