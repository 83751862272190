import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './index.scss';
import SuccessCheck from '../../assets/images/success.svg';
import Loading from '../../assets/images/loading.png';
import { setIdsGenerateForm } from '../../store/reducer/adminSlice';
import { useUpdateStatusMutation } from '../../store/action/adminApi';
const Popup = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateStatus] = useUpdateStatusMutation();
  const number = useSelector((state) => state.search.number);
  useEffect(() => {
    const updateStatusObject = { status: "COMPLETED" }
    const updateIdsStatus = async () => {
      await updateStatus({ id: number, data: updateStatusObject })
    }
    updateIdsStatus()
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
      dispatch(setIdsGenerateForm(true))
      navigate('/idsform');
    }, 2000);
    return () => clearTimeout(timer);
  }, [onClose, navigate]);

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className='message'>
          <span>Generate successfully</span>
          <button onClick={onClose} className='close-button'>X</button>
        </div>
        <div className='pb-2'><img src={SuccessCheck} className="success-icon" alt="Success" /></div>
        <div className='submessage pb-2'>Your data is generated successfully. Please wait for redirect.</div>
        <div className=''><img src={Loading} className='loading-icon' alt="Loading" /></div>
      </div>
    </div>
  );
}

export default Popup;
