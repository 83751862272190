import React from "react";
import "./select-ids.scss";
import Button from "../common/button";

const Popup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="select-ids-popup">
        <div className="message">
          <span>Select IDS</span>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="d-flex submessage py-4">
          <select>
            <option value="Existing IDS">Existing IDS</option>
            <option value="New IDS">New IDS</option>
          </select>
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center py-4">
          <Button
            className={"select-ids-popup-btn mx-2"}
            children="Open"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
