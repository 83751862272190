import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/input";
import Button from "../../components/common/button";
import "./login.scss";
import Form from "../../components/common/Form";
import { useLoginMutation } from "../../store/action/loginApi";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loginUserApi, { isLoading }] = useLoginMutation();

  const handleLogin = async (e) => {
    e.preventDefault();

    const userData = {
      email: username,
      password: password,
    };

    if (!isLoading) {
      await loginUserApi(userData).then(({ data: { accessToken } = {} }) => {
        if (accessToken) {
          if (rememberMe) {
            localStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("accessToken", accessToken);
          } else {
            sessionStorage.setItem("accessToken", accessToken);
          }
          sessionStorage.setItem("userEmail", username);
          navigate("/dashboard");
        } else {
          console.log("token not found");
        }
      });
    }
  };

  return (
    <div className="login-page-container d-flex justify-content-between">
      <div className="login-left">
        <h1 className="welcome-left">
          Welcome to <span>IDS Sign in</span>
        </h1>
        <p className="para">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum is simply dummy text of the printing and
          typesetting industry.
        </p>
      </div>
      <div className="login-right">
        <div className="section-right">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="welcome-right">
              Welcome to <span>IDS</span>
              <br />
              <h1 className="sign-in mt-2">Login</h1>
            </h2>
          </div>
          <Form onSubmit={handleLogin} className="login-form">
            <label className="label">User ID</label>
            <div className="mb-3">
              <Input
                name="email"
                type="text"
                className="form-control"
                placeholder="User ID"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <label className="label">Enter Your Password</label>
            <div className="mb-3">
              <Input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  className="mr-2"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label htmlFor="rememberMe" className="remember-me">
                  Remember Me
                </label>
              </div>
              <span className="forgot-password">Forgot Password</span>
            </div>
            <Button
              type="submit"
              className="btn btn-primary w-100 fw-6 login-button"
            >
              Sign in
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
