import React from "react";
import "./idsform.scss";
import Button from "../../components/common/button";

function IdsFormFooter() {
  return (
    <div className="footer-container d-flex">
      <Button className="footer-btn">Clear</Button>
      <Button className="footer-btn">Email</Button>
      <Button className="footer-btn">Download</Button>
      <Button className="footer-btn blue-btn">Submit USPTO</Button>
    </div>
  );
}

export default IdsFormFooter;
