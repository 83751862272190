import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Button from "../common/button";
import "./index.scss";
import logo from "../../assets/images/Logo.png";
import useAuth from "../../hooks/useAuth";
import { useGetUserProfileQuery } from "../../store/action/userApi";
const Header = ({ isAuthenticate }) => {
  useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userEmail = sessionStorage.getItem("userEmail");
  const getUsername = (email) => {
    if (!email) return '';
    const namePart = email.split('@')[0];
    const firstName = namePart.split('.')[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  };
  const username = getUsername(userEmail);
  const profileInitial = userEmail ? userEmail.charAt(0).toUpperCase() : '';
  const [userName, setUserName] = useState("")
  const { data } = useGetUserProfileQuery({ userEmail: userEmail });
  useEffect(() => {
    if (data) {
      setUserName(data.name)
    }
  }, [data]);

  return (
    <div
      className={`container-fluid sticky-top header-bg shadow-sm ${isAuthenticate ? "dark-bg" : ""
        }`}
      id="header"
    >
      <div className="px-4 w-100">
        <nav className="navbar width-adjust-navbar navbar-expand-lg header-bg navbar-light py-3 py-lg-0">
          <Link to={`${isAuthenticate ? "/dashboard" : ""}`} className="navbar-brand">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          {pathname !== "/login" && (
            <>
              <Button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </Button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                  <Link
                    to="/search"
                    className={`nav-item nav-link ${pathname === "/search" ? "active" : ""
                      }`}
                  >
                    <Trans>{t("Search")}</Trans>
                  </Link>
                  <Link
                    to="/dashboard"
                    className={`nav-item nav-link ${pathname === "/dashboard" ? "active" : ""
                      }`}
                  >
                    <Trans>{t("Dashboard")}</Trans>
                  </Link>
                  {pathname === "/ids" && (
                    <Link
                      to="/ids"
                      className={`nav-item nav-link ${pathname === "/ids" ? "active" : ""
                        }`}
                    >
                      <Trans>{t("Supplementary IDS")}</Trans>
                    </Link>
                  )}
                  <div className="navbar-nav ms-auto py-0">
                    <div className="dropdown-title">
                      {userEmail && username ? (
                        <>
                          <span className="username-text">{username}</span>
                          <div
                            className="profile-initial"
                            onClick={handleLogout}
                            title="Logout"
                          >
                            {profileInitial}
                          </div>
                        </>
                      ) : (
                        <Trans>{t("Username")}</Trans>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {pathname === "/login" && (
            <div className="navbar-nav ms-auto py-0">
              <div className="dropdown-title">
                {userEmail ? (
                  <>
                    <span className="username-text">{username}</span>
                    <div
                      className="profile-initial"
                      onClick={handleLogout}
                      title="Logout"
                    >
                      {profileInitial}
                    </div>
                  </>
                ) : (
                  <Trans>{t(" ")}</Trans>
                )}
              </div>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Header;
