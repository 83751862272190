import { React, useState } from "react";
import PropTypes from "prop-types";
import RefArrow from "../../assets/images/ref-available-arrow.png";
import "./index.scss";

const Table = ({
  headers,
  data,
  renderAsHtml,
  availableReferenceData = [],
}) => {
  const [openRows, setOpenRows] = useState({});
  const toggleRow = (rowId) =>
    setOpenRows((previous) => ({
      ...previous,
      [rowId]: !(previous[rowId] ?? false),
    }));
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <>
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    dangerouslySetInnerHTML={
                      renderAsHtml && header === "Link"
                        ? { __html: row[header] }
                        : undefined
                    }
                  >
                    {header === "Availability" &&
                    row[header] === "Ref# Available" ? (
                      <>
                        <span className="ref-avail-class">
                          {row[header]}
                          <img
                            src={RefArrow}
                            className="ref-arrow-img mx-1"
                            alt="Dropdown"
                            onClick={() => toggleRow(rowIndex)}
                          />
                        </span>
                        <span className="ref-count">{row["citedCounts"]}</span>
                      </>
                    ) : !renderAsHtml || header !== "Link" ? (
                      row[header]
                    ) : null}
                  </td>
                ))}
              </tr>
              {openRows[rowIndex] === true ? (
                availableReferenceData
                  .filter(
                    (refData) =>
                      refData["Serial #"] === row["Serial #"] &&
                      refData["Patent #"] === row["Patent #"]
                  )
                  .map((refData) => (
                    <tr>
                      <td colSpan="3">
                        <span className="ref-title">Publication No.:</span>{" "}
                        {refData["Publication No."]}
                      </td>
                      <td colSpan="3">
                        <span className="ref-title">Kind Code:</span>{" "}
                        {refData["Kind Code"]}
                      </td>
                      <td colSpan="3">
                        <span className="ref-title">Publication Date:</span>{" "}
                        {refData["Publication Date"]}{" "}
                      </td>
                      <td colSpan="5">
                        <span className="ref-title">Patentee Name:</span>{" "}
                        {refData["Patentee Name"]}{" "}
                      </td>
                    </tr>
                  ))
              ) : (
                <></>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderAsHtml: PropTypes.bool,
};

Table.defaultProps = {
  renderAsHtml: false,
};

export default Table;
