import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Examinerform = (props) => {
  const { field1, field2 } = props;
  return (
    <>
      <Form className="form-class d-flex justify-content-between">
        <Row className="row-container">
          <Col className="col3-container">
            <Form.Label>{field1}</Form.Label>
            <Form.Control
              className="examiner-input"
              type="text"
              placeholder={field1}
            />
          </Col>
          <Col className="col3-container">
            <Form.Label>{field2}</Form.Label>
            <Form.Control
              className="examiner-input"
              type="text"
              placeholder={field2}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Examinerform;
