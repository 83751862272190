import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import SuccessCheck from '../../assets/images/success.svg';
import Loading from '../../assets/images/loading.png';

function Popup({ onClose }) {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
      navigate('/idsform');
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose, navigate]);

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className='message'>
          <span>Generate successfully</span>
          <button onClick={onClose} className='close-button'>X</button>
        </div>
        <div className='pb-2'><img src={SuccessCheck} className="success-icon" alt="Success" /></div>
        <div className='submessage pb-2'>Your data is generated successfully. Please wait for redirect.</div>
        <div className=''><img src={Loading} className='loading-icon' alt="Loading" /></div>
      </div>
    </div>
  );
}

export default Popup;
