import React from 'react';
import { useSelector } from 'react-redux';
import './summary.scss';

function SummaryDetails() {
  const { familyBiblio } = useSelector(state => state.family);

  const familyCount = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['@total-result-count'] || 'N/A';
  
  const getUSMembers = () => {
    const usMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member']?.filter(member => member['country'] === 'US') || [];
    const activeCount = usMembers.filter(member => member['status'] === 'Active').length;
    const deadCount = usMembers.filter(member => member['status'] === 'Dead').length;
    return { active: activeCount, dead: deadCount };
  };

  const getForeignMembers = () => {
    const foreignMembers = familyBiblio?.['ops:world-patent-data']?.['ops:patent-family']?.['ops:family-member']?.filter(member => member['country'] !== 'US') || [];
    const activeCount = foreignMembers.filter(member => member['status'] === 'Active').length;
    const deadCount = foreignMembers.filter(member => member['status'] === 'Dead').length;
    return { active: activeCount, dead: deadCount };
  };

  const usMembers = getUSMembers();
  const foreignMembers = getForeignMembers();

  const data = {
    "Family Count": familyCount,
    "U.S. Members": `Active: ${usMembers.active}   Dead: ${usMembers.dead}`,
    "Foreign Members": `Active: ${foreignMembers.active}   Dead: ${foreignMembers.dead}`,
    "Legal Status": "", 
  };

  return (
    <div className="details-container">
      <div className="summary-content">
        {Object.keys(data).map((key, index) => (
          <div className="row" key={index}>
            <div className="key">{key}</div>
            <div className="value">{data[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SummaryDetails;
